import React, { cloneElement } from 'react';

import {
  SimpleForm,
  TextInput,
  useEditController,
  useTranslate,
  SaveButton, Toolbar,
  Edit,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  DateInput,
  Loading,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Grid } from '@material-ui/core';
import { TabbedLayout, Tab } from '../../lib/components';
import RichTextEditor from '../../lib/components/RichTextEditor';
import UploadImage from '../promotions/HandleImage/UploadImage';
import { ERROR_TEXT } from './constants';
import PromotionReferenceList from './PromotionReferenceList';
import { getDefaultEnvRetailerImage } from '../../lib/utils';


const IMAGE_WIDTH = 80;
const IMAGE_HEIGHT = 30;
const defaultRetailerImageUrl = getDefaultEnvRetailerImage();

const useStyles = makeStyles(theme => ({
  formControlFields: {
    width: '35%',
  },
  helpLabel: {
    color: 'black',
    fontSize: 14,
  },
  helpContent: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  date: {
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    width: '200px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const CustomEdit = (props) => {
  const {
    record,
    ...rest
  } = useEditController(props);
  const { children } = props;
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab label={translate('retailers.tabs.details')}>
          <Card>
            {cloneElement(children, {
              record,
              ...rest,
            })}
          </Card>
        </Tab>
        <Tab label={translate('retailers.tabs.promotions')} path="promotions" data-cy="promotions-tab">
          {
          !record?.id ? <Loading />
            : <PromotionReferenceList {...props} id={record?.id} />
          }
        </Tab>
      </TabbedLayout>
    </div>
  );
};

const RetailerToolBar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const RetailerEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const {
    record,
  } = useEditController(props);
  const classes = useStyles();

  const InformationView = () => (
    <Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.format')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.formatValue')}</FormLabel>
      </Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.weight')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.weightValue')}</FormLabel>
      </Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.size')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.sizeValueRetailer')}</FormLabel>
      </Grid>
    </Grid>
  );

  return (
    <CustomEdit {...props} undoable={false}>
      <Edit {...props} mutationMode="undoable" title={`Retailer #${record?.id}`}>
        <SimpleForm
          redirect={false}
          data-testid="retailer-edit-form"
          toolbar={<RetailerToolBar />}
        >
          <TextInput
            source="name"
            fullWidth
            resettable
            validate={required(ERROR_TEXT)}
            label={translate('retailers.edit.name')}
          />
          <RichTextEditor
            multiline
            fullWidth
            source="description"
            resettable
            label={translate('retailers.edit.description')}
            curValue={record?.description}
          />
          <RadioButtonGroupInput
            source="status"
            choices={[
              { id: 'Active', name: 'Active' },
              { id: 'Inactive', name: 'Inactive' },
            ]}
            validate={required(ERROR_TEXT)}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <UploadImage
                {...props}
                id={formData.id}
                source="imageUrl"
                accept="image/jpeg,image/png,image/jpg"
                originalUrl={formData.imageUrl}
                defaultValue={defaultRetailerImageUrl}
                maxSize={5000000}
                customSource="imageRetailers"
                previewWidthAndHeight={{ width: 80, height: 'auto' }}
                label={translate('retailers.edit.logo')}
                placeholder={<p>{translate('image.ImageDropLabel')}</p>}
                maxImageWidth={IMAGE_WIDTH}
                maxImageHeight={IMAGE_HEIGHT}
                informationComponent={() => <InformationView />}
              />
            )}
          </FormDataConsumer>
          <DateInput
            source="createdAt"
            label={translate('retailers.edit.creationDate')}
            disabled
            className={classes.date}
          />
        </SimpleForm>
      </Edit>
    </CustomEdit>
  );
};

export default RetailerEdit;
