import * as React from 'react';

import {
  List, Datagrid, TextField, BooleanField, Filter, TextInput,
} from 'react-admin';
import { EmptyList } from '../../lib/components';

const ProductsFilter = ({ translate, ...props }) => (
  <Filter {...props} data-testid="product-filter">
    <TextInput source="ean" alwaysOn label="EAN" />
  </Filter>
);

const ProductList = props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    empty={<EmptyList />}
    filters={(
      <ProductsFilter />
    )}
  >
    <Datagrid rowClick="edit">
      <TextField source="ean" label="EAN" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
);

export default ProductList;
