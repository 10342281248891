import { ApolloLink } from '@apollo/client';

import { isExpired } from '../lib/utils/date';

const RefreshMiddleware = new ApolloLink((operation, forward) => {
  const apolloToken = localStorage.getItem('token');
  const isValidExpiredToken = () => {
    const expires = localStorage.getItem('expires');
    return expires === null || !isExpired(expires);
  };

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: apolloToken ? `Bearer ${apolloToken}` : '',
    },
  }));

  return forward(operation).map((data) => {
    if (data?.errors?.[0]?.message === 'Invalid token rejected by oAuth2 service.' && isValidExpiredToken()) {
      localStorage.setItem('token', '');
      localStorage.setItem('expires', '');
      localStorage.setItem('refresh', '');
    }
    return data;
  });
});

export default RefreshMiddleware;
