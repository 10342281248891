import { makeStyles } from '@material-ui/core/styles';

export const uploadCouponCodesButton = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.90rem',
    marginTop: '2px',
  },
  fileLabel: {
    fontWeight: 'bold',
  },
  check: {
    color: 'green',
    margin: '3px 0 0 6px',
  },
  loadingCircle: {
    padding: '0 10px 0 10px',
  },
  loadingCircleBar: {
    color: '#000000',
    padding: '0 10px',
  },
  progressBar: {
    position: 'relative',
    width: '100%',
    height: '15px',
    border: '1px solid #EFEFEF',
    backgroundColor: '#FFFFFF',
    margin: '5px 0',
    borderRadius: '5px',
  },
  progressBarText: {
    position: 'absolute',
    width: '100%',
    height: '15px',
    lineHeight: '15px',
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#000000',
    zIndex: 1,
  },
  filledBar: {
    height: '15px',
    backgroundColor: '#0099FF',
    borderRadius: '5px',
  },
}));

export default uploadCouponCodesButton;
