import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import 'react-quill/dist/quill.snow.css';
import { addField, FieldTitle } from 'ra-core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
} from 'react-admin';
import {
  InputLabel, FormControl, withStyles, DialogActions,
  Button, DialogContentText, TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import { AUTO_PUBLISH_STATUS, ACTIVE_STATUS } from './constants';

const styles = {
  label: {
    position: 'relative',
  },
};

const useStyles = makeStyles(() => ({
  menuItem: {
    marginBottom: 20,
  },
  errorField: {
    fontSize: 12,
    color: 'red',
    marginTop: 2,
  },
  date: {
    marginBottom: 20,
    width: '100%',
  },
  input: {
    marginTop: '30px',
  },
}));

const StatusDateInputField = (props) => {
  const translate = useTranslate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const classes = useStyles();

  const {
    meta: { touched, error },
    label,
    id,
    source,
    resource,
    isRequired,
    input: { value },
    currStatus,
    setCurrStatus,
  } = props;

  const openActiveDialog = (status, date) => {
    if (status && status === ACTIVE_STATUS
      && moment(date).isAfter(moment(), 'days')) {
      setOpenDialog(true);
    }
  };

  const handleDateChange = (date) => {
    const { input: { onChange }, onChangeDate } = props;
    setSelectedDate(date.target.value);
    onChange(date);
    onChangeDate(date);
    openActiveDialog(currStatus, date.target.value);
  };

  React.useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  React.useEffect(() => {
    if (currStatus === ACTIVE_STATUS && !value) {
      handleDateChange({
        target: {
          value: moment().format('YYYY-MM-DD'),
        },
      });
    }
    if (currStatus && selectedDate === value) {
      openActiveDialog(currStatus, value);
    }
  }, [currStatus]);

  return (
    <>
      <FormControl
        error={!!(touched && error)}
        className={classes.date}
      >
        {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        )}
        <TextField
          id="date"
          type="date"
          value={value}
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleDateChange}
        />
        {touched && error && (
        <Typography className={classes.errorField}>
          {error}
        </Typography>
        )}
      </FormControl>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('promotion.status.manualChangeStatusTitle')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            {translate('promotion.status.changeStatusNegative')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (setCurrStatus) {
                setCurrStatus(AUTO_PUBLISH_STATUS);
              }
              setOpenDialog(false);
            }}
          >
            {translate('promotion.status.changeStatusPositive')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StatusDateInput = addField(withStyles(styles)(StatusDateInputField));

StatusDateInput.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

StatusDateInput.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default StatusDateInput;
