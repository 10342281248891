import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

import moment from 'moment/moment';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {
  useNotify,
  useTranslate,
  useRefresh,
} from 'ra-core';
import {
  ACTIVE_STATUS,
  FIRST_ITEM,
  ORDER_STATUS,
  ORDER_STATUS_FROM_REQUESTED,
  PROMOTION_TYPES,
  REDEEMED,
  REQUESTED,
} from '../promotions/constants';

const StatusSelect = ({
  id, status, promotionStatus, promotionName, promotionType, retailerList, orderRetailer,
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const [selectedStatus, setSelectedStatus] = useState(status);
  const [previousStatus, setPreviousStatus] = useState(status);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosenReason, setChosenReason] = useState(undefined);
  const [concept, setConcept] = useState(undefined);
  const [retailerSelected, setRetailerSelected] = useState(undefined);
  const [statusDate, setStatusDate] = useState(undefined);
  const [statusValues, setStatusValues] = useState(ORDER_STATUS);

  useEffect(() => {
    if (previousStatus === REQUESTED) {
      setStatusValues(ORDER_STATUS_FROM_REQUESTED);
      setStatusDate(moment().utc().format());
    } else {
      setStatusValues(ORDER_STATUS);
    }
  }, [previousStatus]);

  useEffect(() => {
    if (promotionType === PROMOTION_TYPES.REDEMPTION_POINT) {
      setRetailerSelected(orderRetailer);
    }
  }, []);

  const updateOrder = () => {
    setLoading(true);
    dataProvider.update('orders', {
      id,
      input: {
        status: selectedStatus,
        reason: chosenReason,
        concept,
        retailer: retailerSelected,
        statusDate,
      },
    })
      .then(() => {
        setPreviousStatus(selectedStatus);
        setOpenDialog(false);
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        notify(error?.message, 'error');
        setLoading(false);
      });
  };

  const parseConcept = (newStatus) => {
    let parsedConcept;

    switch (newStatus) {
      case 'REQUESTED':
        parsedConcept = `${translate('orders.statusChange.concept.requested')} ${promotionName}`;
        break;
      case 'CANCELLED':
        parsedConcept = `${translate('orders.statusChange.concept.cancelled')} ${promotionName}`;
        break;
      case 'REDEEMED':
        parsedConcept = `${translate('orders.statusChange.concept.redeemed')} ${promotionName}`;
        break;
      default:
        parsedConcept = '-';
    }

    setConcept(parsedConcept);
  };

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    parseConcept(event.target.value);
    setOpenDialog(true);
  };

  const handleChangeRetailer = (event) => {
    setRetailerSelected(event.target.value);
  };

  const closeDialog = () => {
    setSelectedStatus(previousStatus);
    setOpenDialog(false);
  };

  const onReasonTextChange = (event) => {
    const val = event?.target.value;
    setChosenReason(val);
  };

  const updateStatus = () => {
    updateOrder();
  };

  const changeStatusReason = () => (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translate('orders.statusChange.statusUpdateTitle')}</DialogTitle>
      <DialogContent>
        <TextField
          id="reason-other"
          label={translate('orders.statusChange.statusUpdateDescription')}
          fullWidth
          multiline
          value={chosenReason}
          onChange={onReasonTextChange}
        />
      </DialogContent>
      {promotionType !== PROMOTION_TYPES.REDEMPTION_POINT
        && promotionType !== PROMOTION_TYPES.HOME_DELIVERY
        && selectedStatus === REDEEMED && (
        <>
          <DialogTitle id="alert-dialog-title">{translate('orders.statusChange.selectRetailerTitle')}</DialogTitle>
          <DialogContent>
            <Select value={retailerSelected} onChange={handleChangeRetailer} placeholder="Select one" fullWidth>
              {retailerList?.map(retailer => (
                <MenuItem key={retailer.id} value={retailer.id}>{retailer.name}</MenuItem>
              ))}
            </Select>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button
          onClick={updateStatus}
          color="primary"
          disabled={loading
            || (selectedStatus === REDEEMED
              && !retailerSelected && promotionType !== PROMOTION_TYPES.HOME_DELIVERY)}
        >
          {translate('orders.statusChange.submit')}
        </Button>
        <Button onClick={closeDialog} color="primary" autoFocus disabled={loading}>
          {translate('orders.statusChange.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );


  if (!ORDER_STATUS[status] || (status === ORDER_STATUS.CANCELLED
     && promotionStatus !== ACTIVE_STATUS)) {
    return <Typography>{status} </Typography>;
  }

  return (
    <>
      <Select labelId="status-label-order" id="order-status-dropdown" value={selectedStatus} onChange={handleChange}>
        {Object.entries(statusValues).map(item => (
          <MenuItem key={item[FIRST_ITEM]} value={item[FIRST_ITEM]}>{item[FIRST_ITEM]}</MenuItem>
        ))}
      </Select>
      {changeStatusReason()}
    </>
  );
};

export default StatusSelect;
