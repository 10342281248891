import {
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';

import { RETAILERS } from './constants';
import { convertHtmlToMarkdown } from '../../lib/utils/utils';

const exporter = (retailers) => {
  moment.locale('fr',
    {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
    });
  const retailerExport = retailers.map((retailer) => {
    const {
      name, description, imageUrl, active, createdAt,
    } = retailer;
    return {
      name,
      description: convertHtmlToMarkdown(description),
      imageUrl,
      status: active ? ' Active' : 'Inactive',
      createdAt: moment(createdAt).isValid() ? moment(createdAt).format('L') : 'Unknown date',
    };
  });

  jsonExport(retailerExport, {
    headers: [
      'name',
      'description',
      'imageUrl',
      'status',
      'createdAt',
    ],
    rename: [
      'Name',
      'Description',
      'Image Url',
      'Status',
      'Creation Date',
    ],
  }, (err, csv) => {
    downloadCSV(csv?.replace(/,/g, ';'), RETAILERS); // download as 'orders.csv` file
  });
};

export default exporter;
