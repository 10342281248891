import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { addField, FieldTitle } from 'ra-core';
import PropTypes from 'prop-types';

import {
  InputLabel, FormControl, withStyles, Select, MenuItem, Checkbox, ListItemText,
} from '@material-ui/core';
import { STATUS, NO_FOUND_INDEX } from './constants';

const styles = {
  label: {
    position: 'relative',
  },
};


const MultiSelectDropdownComp = (props) => {
  const [values, setValues] = React.useState([]);

  const {
    meta: { touched, error },
    label,
    id,
    source,
    resource,
    isRequired,
    classes,
    choices,
    className,
  } = props;

  const handleValueChange = (val) => {
    const { input: { onChange } } = props;
    onChange(val);
  };

  const renderValue = (selected) => {
    const val = STATUS.reduce((acc, curr) => {
      if (selected.indexOf(curr?.key) > NO_FOUND_INDEX) {
        return [...acc, `${curr.code}. ${curr.status}`];
      }
      return acc;
    }, []);
    return val.join(', ');
  };

  return (
    <FormControl
      error={!!(touched && error)}
      className={className}
    >
      {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <Select
        id="grouped-select"
        multiple
        value={values}
        renderValue={renderValue}
        onChange={(data) => {
          setValues(data.target.value);
          handleValueChange(data.target.value);
        }}
      >
        {
            choices.map(choice => (
              <MenuItem value={choice.key} id={choice.code}>
                <Checkbox checked={values.indexOf(choice.key) > NO_FOUND_INDEX} />
                <ListItemText primary={`${choice.code}. ${choice.status}`} />
              </MenuItem>
            ))
          }
      </Select>
    </FormControl>
  );
};

const MultiSelectDropdown = addField(withStyles(styles)(MultiSelectDropdownComp));

MultiSelectDropdown.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default MultiSelectDropdown;
