import * as React from 'react';
import {
  FormWithRedirect,
} from 'react-admin';

import ProfileFormUi from './ProfileFormUi';


const ProfileForm = props => (
  <FormWithRedirect
    {...props}
    validate={null}
    render={() => (<ProfileFormUi {...props} />)}
  />
);

export default ProfileForm;
