import React, { Fragment, useState } from 'react';
import {
  Button,
  useNotify,
  useDataProvider,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import { useForm } from 'react-final-form';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(() => ({
  cancelIcon: {
    zIndex: 2,
    position: 'absolute',
    right: 0,
    margin: '3px',
    cursor: 'pointer',
  },
  imageGradient: {
    position: 'absolute',
    zIndex: 1,
    background: 'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.7875525210084033) 13%, rgba(255,255,255,0) 50%)',
  },
  removeTitle: {
    padding: 0,
  },
}));

const ClearFieldsButton = ({
  setDialogue, ...rest
}) => (
  <Button onClick={() => setDialogue(false)} {...rest}>
    <IconCancel />
  </Button>
);


const ShowImage = (props) => {
  const [showDialog, setDialogue] = useState(false);
  const {
    id, src, customSource, previewWidth, previewHeight, hideGradient, onChange,
  } = props;
  const defaultWidth = 100;
  const defaultHeight = 100;
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const form = useForm();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const classNames = useStyles();

  const ImageGradient = () => {
    if (loaded) {
      return (
        <>
          <Box
            className={hideGradient ? null : classNames.imageGradient}
            style={{ height: previewHeight, width: previewWidth }}
            display="inline"
          />
          <IconCancel
            className={classNames.cancelIcon}
            color="error"
            fontSize="small"
            onClick={() => setDialogue(true)}
          />
        </>
      );
    }
    return null;
  };

  const approve = (file) => {
    setLoading(true);
    return dataProvider
      .delete(customSource, { id, input: { extension: (file.split('.').pop().split(/[#?]/)[0]) || 'jpg' } })
      .then(({ data }) => {
        if (data) {
          form.change('imageUrl', undefined);
          setDialogue(false);
          notify(translate('image.delete.success'), 'success');
          if (onChange) {
            onChange('imageUrl', { target: { value: null } });
          }
        } else {
          notify(translate('image.delete.tryAgain'), 'warning');
        }
        setLoading(false);
      })
      .catch((error) => {
        notify(`${translate('image.delete.error')}:, ${error.message}`, 'warning');
        setLoading(false);
      });
  };


  return (
    <Fragment>
      <Box display="inline" position="relative">
        <ImageGradient />
        <img src={src} width={previewWidth || defaultWidth} height={previewHeight || defaultHeight} alt="promotion" onLoad={() => setLoaded(true)} />
      </Box>
      <Dialog fullWidth open={showDialog} onClose={() => setDialogue(false)} aria-label="Remove image">
        <DialogTitle>{translate('ra.action.remove')}</DialogTitle>
        <DialogContent>
          <DialogTitle className={classNames.removeTitle}>
            {translate('image.deleteWarning')}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.remove"
            onClick={() => approve(src)}
            disabled={loading}
          >
            <IconSave />
          </Button>

          <ClearFieldsButton label={translate('ra.action.cancel')} setDialogue={setDialogue} />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ShowImage;
