import _ from 'lodash';

const PHONE_PREFIX = '+507 ';
const NOT_ANSWERED = 'NOT_ANSWERED';
const YES = 'YES';
const NO = 'NO';
const genderOptions = translate => [
  { id: 'MALE', name: translate('user.edit.gender.man') },
  { id: 'FEMALE', name: translate('user.edit.gender.woman') },
  { id: 'OTHER', name: translate('user.edit.gender.other') },
];

const binaryOptions = translate => [
  { id: YES, name: translate('user.edit.yes') },
  { id: NO, name: translate('user.edit.no') },
  { id: NOT_ANSWERED, name: translate('user.edit.not_answered') },
];
const petsOptions = translate => [
  { id: 'DOG', name: translate('user.edit.Dog') },
  { id: 'CAT', name: translate('user.edit.Cat') },
  { id: 'BIRD', name: translate('user.edit.Bird') },
  { id: 'MOUSE', name: translate('user.edit.Mouse') },
  { id: 'FISH', name: translate('user.edit.Fish') },
  { id: 'OTHER', name: translate('user.edit.Other') },
];
const interestOptions = translate => [
  { id: 'sports', name: translate('user.edit.sports') },
  { id: 'reading', name: translate('user.edit.reading') },
  { id: 'cultural', name: translate('user.edit.cultural') },
];
const lifestyleOptions = translate => [
  { id: 'health', name: translate('user.edit.health') },
  { id: 'selfEducation', name: translate('user.edit.selfEducation') },
  { id: 'environment', name: translate('user.edit.environment') },
  { id: 'aesthetics', name: translate('user.edit.aesthetics') },
  { id: 'healthWithoutTime', name: translate('user.edit.healthWithoutTime') },
  { id: 'socialMedia', name: translate('user.edit.socialMedia') },
  { id: 'qualityTime', name: translate('user.edit.qualityTime') },
  { id: 'careerAndFamily', name: translate('user.edit.careerAndFamily') },
  { id: 'closeFriends', name: translate('user.edit.closeFriends') },
  { id: 'gardening', name: translate('user.edit.gardening') },
];
const dietOptions = translate => [
  { id: 'omnivorous', name: translate('user.edit.omnivorous') },
  { id: 'flexitarian', name: translate('user.edit.flexitarian') },
  { id: 'vegetarian', name: translate('user.edit.vegetarian') },
  { id: 'vegan', name: translate('user.edit.vegan') },
  { id: 'other', name: translate('user.edit.other') },
];
const homeComponentOptions = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: '11', name: '11' },
];
const childComponentOptions = [
  { id: '0', name: '0' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: 'NOT_ANSWERED', name: 'NOT_ANSWERED' },
];
const parseProfileForm = (formInput) => {
  const policies = [];
  const notAnswered = [];

  const policiesOptions = [
    {
      code: 'platformCommunications',
      name: 'Platform Communications',
    },
    {
      code: 'retailerCommunications',
      name: 'Retailer Communications',
    },
    {
      code: 'dataLinkWithSAZ',
      name: 'Link profile with ShopAdvizor',
    },
    {
      code: 'thirdPartyCommunications',
      name: 'Third Party Communications',
    },
  ];
  const {
    userId,
    gender,
    birthDate,
    address,
    petsValues,
    numberOfHomeComponents,
    partner,
    educationCode,
    phoneValue,
    children,
    lifestylesValues,
    interestsValues,
    dietValue,
    hasAlcohol,
    numberOfChildren,
    hasPet,
    identifier,
  } = formInput;
  const allPolicies = ['platformCommunications', 'retailerCommunications', 'dataLinkWithSAZ', 'thirdPartyCommunications'];

  allPolicies.forEach((policy) => {
    if (formInput[policy] === true) {
      policies.push(policiesOptions.find(item => item.code === policy));
    }
  });
  let petsForGraphQl = petsValues?.map(item => ({ type: item })) ?? [];
  const childrenForGraphQl = children?.map(item => ({ birthDate: item?.birthDate, firstName: '' })) ?? [];
  const alcoholForGraphQl = hasAlcohol === YES;
  if (hasAlcohol === NOT_ANSWERED) {
    notAnswered.push('alcohol');
  }
  if (numberOfChildren === NOT_ANSWERED) {
    notAnswered.push('children');
  }
  if (hasPet === NOT_ANSWERED) {
    notAnswered.push('pets');
  }
  if (hasPet === NO) {
    petsForGraphQl = [];
  }
  return {
    policies,
    userId,
    gender,
    birthDate,
    address,
    identifier: identifier ?? null,
    preference: (hasAlcohol === NOT_ANSWERED) ? {} : { alcohol: alcoholForGraphQl },
    partner: _.omit(partner, '__typename'),
    numberOfHomeComponents,
    pets: hasPet === NOT_ANSWERED ? null : petsForGraphQl,
    lifestyles: lifestylesValues?.map(item => ({ code: item })),
    interests: interestsValues?.map(item => ({ code: item })),
    diet: dietValue ? { code: dietValue } : null,
    children: numberOfChildren === NOT_ANSWERED ? null : childrenForGraphQl,
    phoneNumber: phoneValue ? PHONE_PREFIX + phoneValue : null,
    notAnswered,
    educationLevel: {
      code: educationCode,
    },
  };
};
const parseEditProfileForm = (formInput) => {
  const {
    id,
  } = formInput;
  const profileEdit = parseProfileForm(formInput);
  return { id, ...profileEdit };
};
const parseCreateProfileForm = (formInput) => {
  const profileEdit = parseProfileForm(formInput);
  return { ...profileEdit };
};

const buildResponse = (response) => {
  const output = response;
  if (_.has(response, 'policies')) {
    const policies = {
      thirdPartyCommunications: _.find(response?.policies, { code: 'thirdPartyCommunications' }) !== undefined,
      retailerCommunications: _.find(response?.policies, { code: 'retailerCommunications' }) !== undefined,
      platformCommunications: _.find(response?.policies, { code: 'platformCommunications' }) !== undefined,
      dataLinkWithSAZ: _.find(response?.policies, { code: 'dataLinkWithSAZ' }) !== undefined,
    };
    _.assignIn(output, policies);
  }
  if (_.has(response, 'diet')) {
    if (!_.isEmpty(response?.diet)) {
      _.assignIn(output, { diet: response?.diet?.code });
    }
  }

  if (_.has(response, 'educationLevel.code')) {
    _.assignIn(output, { educationCode: response?.educationLevel.code });
  }
  const zero = 0;
  const outReach = -1;
  const alcoholValue = response?.preference?.alcohol ? 'YES' : 'NO';
  const petValue = response?.pets?.length > zero ? 'YES' : 'NO';
  const hasAlcohol = response?.notAnswered?.length > zero && response?.notAnswered?.indexOf('alcohol') !== outReach ? 'NOT_ANSWERED' : alcoholValue;
  const hasPet = response?.notAnswered?.length > zero && response?.notAnswered?.indexOf('pets') !== outReach ? 'NOT_ANSWERED' : petValue;
  const childrenLenght = response?.children ? response?.children?.length.toString() : '0';
  const numberOfChildren = response?.notAnswered && response?.notAnswered?.length && response?.notAnswered?.indexOf('children') !== outReach
    ? 'NOT_ANSWERED'
    : childrenLenght;
  return {
    ...output,
    children: response?.children || [],
    lifestylesValues: response?.lifestyles?.map(item => item.code),
    interestsValues: response?.interests?.map(item => item.code),
    petsValues: response?.pets?.map(item => item.type),
    dietValue: output?.diet?.code ? output?.diet?.code : output?.diet,
    phoneValue: response?.phoneNumber ? response?.phoneNumber.split(' ')[1] : null,
    phonePrefix: PHONE_PREFIX,
    platformCommunications: _.find(response?.policies, { code: 'platformCommunications' }) !== undefined,
    numberOfChildren,
    hasAlcohol,
    hasPet,
  };
};

export {
  dietOptions,
  lifestyleOptions,
  interestOptions,
  petsOptions,
  genderOptions,
  homeComponentOptions,
  binaryOptions,
  childComponentOptions,
  parseProfileForm,
  parseEditProfileForm,
  parseCreateProfileForm,
  PHONE_PREFIX,
  buildResponse,
};
