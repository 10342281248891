import { makeStyles } from '@material-ui/core/styles';

const userPointsTabStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsHeader: {
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    '& > button': {
      margin: '5px',
    },
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
    fontWeight: '400',
  },
  deleteButton: {
    marginLeft: '5px',
  },
  userPoints: {
    padding: '7px',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
    },
    margin: '0 5px 0 5px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  noPointsMessage: {
    padding: '20px',
    fontSize: '0.875rem',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
    lineHeight: '1.5rem',
  },
}));

export default userPointsTabStyles;
