import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl, InputLabel, makeStyles, TextField, Typography, withStyles,
} from '@material-ui/core';
import { addField } from 'ra-core';
import PropTypes from 'prop-types';
import { FieldTitle } from 'ra-core/esm/util/FieldTitle';
import { ACTIVE_STATUS, FIRST_ITEM } from './constants';

const styles = {
  label: {
    position: 'relative',
  },
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 20,
    width: '100%',
  },
  title: {
    width: '120%',
  },
  input: {
    marginTop: '30px',
  },
  errorField: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginTop: 2,
  },
}));

const NumericInput = (props) => {
  const {
    meta: { error, touched },
    className,
    source,
    label,
    isRequired,
    resource,
    id,
    disabled,
    readOnly,
    onValueChanged,
    status,
    initialStock,
    checkInitialStock,
    input: { value, onChange },
    min,
    max,
  } = props;
  const classes = useStyles();
  const [textValue, setTextValue] = React.useState(value);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [textEvent, setTextEvent] = React.useState(null);
  const [errorShownOnce, setShowErrorShownOnce] = React.useState(false);

  const updateValue = (val) => {
    const { maxLength, maxValue } = props;
    if ((val <= maxValue || val?.length <= maxLength)
    || (!maxLength && !maxValue)) {
      onChange(val);
      setTextValue(val);
      if (onValueChanged) {
        onValueChanged(val);
      }
    }
  };

  const onHandleChange = (event) => {
    if (status === ACTIVE_STATUS && !errorShownOnce) {
      setOpenDialog(true);
      setShowErrorShownOnce(true);
      setTextEvent(event?.target?.value);
    } else {
      updateValue(event?.target?.value);
    }
  };

  useEffect(() => {
    if (initialStock?.length === FIRST_ITEM && checkInitialStock) {
      setTextValue('');
    }
  }, [initialStock, checkInitialStock]);

  return (
    <div className={classes.container}>
      <FormControl
        error={!!(touched && error)}
        className={className}
      >
        {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.title}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        )}
        <TextField
          value={textValue}
          type="number"
          onChange={onHandleChange}
          disabled={disabled}
          className={classes.input}
          id={id}
          InputProps={{ inputProps: { min, max, readOnly } }}
        />
        {touched && error && (
        <Typography className={classes.errorField}>
          {error}
        </Typography>
        )}
      </FormControl>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attention: Are you sure you want to update the stock?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTextEvent(null);
              setOpenDialog(false);
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateValue(textEvent);
              setTextEvent(null);
              setOpenDialog(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const StatusDropdown = addField(withStyles(styles)(NumericInput));

StatusDropdown.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

StatusDropdown.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default StatusDropdown;
