import {
  downloadCSV,
} from 'react-admin';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';
import {
  CANCELLED,
  EVALUATED, EXPIRED, FIRST_ITEM, REDEEMED, REQUESTED,
} from '../promotions/constants';

const exporter = (orders, pendingOrdersCount) => {
  moment.locale('fr',
    {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
    });

  const getCurrentStatus = (statusHistory, status) => {
    const currentStatus = statusHistory
      .filter(item => item.status === status)
      .sort((statusOne, statusTwo) => moment(statusTwo.statusDate).diff(statusOne.statusDate));
    if (currentStatus?.length) {
      return currentStatus[0];
    }
    return {};
  };

  const getDate = (date) => {
    if (date && moment(date).isValid()) {
      return moment(date).format('L');
    }
    return 'Unknown date';
  };

  const ordersExport = orders.map((order) => {
    const {
      user, promotion, statusHistory, status, retailer,
    } = order;

    const requestedAt = getCurrentStatus(statusHistory, REQUESTED).statusDate;
    const expiredAt = getCurrentStatus(statusHistory, EXPIRED).statusDate;
    const redeemedAt = getCurrentStatus(statusHistory, REDEEMED).statusDate;
    const evaluatedAt = getCurrentStatus(statusHistory, EVALUATED).statusDate;
    const cancelledSource = status === CANCELLED && CANCELLED;
    const cancelledAt = cancelledSource
     && getCurrentStatus(statusHistory, cancelledSource).statusDate;

    const { reason } = getCurrentStatus(statusHistory, status);

    return {
      username: user ? user.username : undefined,
      promotionKey: promotion ? promotion.key : undefined,
      promotionName: promotion ? promotion.name : undefined,
      requestedAt: getDate(requestedAt),
      expiredAt: getDate(expiredAt),
      redeemedAt: getDate(redeemedAt),
      evaluatedAt: getDate(evaluatedAt),
      cancelledAt: getDate(cancelledAt),
      retailer: retailer?.name || '',
      status,
      reason,
      pendingOrdersCount,
    };
  });

  const headers = [
    'username',
    'promotionKey',
    'promotionName',
    'requestedAt',
    'expiredAt',
    'redeemedAt',
    'evaluatedAt',
    'cancelledAt',
    'retailer',
    'status',
    'reason',
  ];

  const rename = [
    'Username',
    'Promotion Key',
    'Promotion Name',
    'Requested date',
    'Expired date',
    'Redeemed date',
    'Evaluated date',
    'Cancelled date',
    'Retailer',
    'Status',
    'Reason',
  ];

  if (pendingOrdersCount >= FIRST_ITEM) {
    headers.push('pendingOrdersCount');
    rename.push('Orders pending completion');
  }

  jsonExport(ordersExport, {
    headers,
    rename,
  }, (err, csv) => {
    downloadCSV(csv?.replace(/,/g, ';'), 'orders'); // download as 'orders.csv` file
  });
};


export default exporter;
