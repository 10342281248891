import PromotionIcon from '@material-ui/icons/LocalOffer';
import PromotionEdit from './PromotionEdit';
import PromotionList from './PromotionList';
import PromotionShow from './PromotionShow';
import PromotionCreate from './PromotionCreate';

export default {
  create: PromotionCreate,
  list: PromotionList,
  edit: PromotionEdit,
  show: PromotionShow,
  icon: PromotionIcon,
};
