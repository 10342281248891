import StaticIcon from '@material-ui/icons/MenuBook';
import StaticList from './StaticList';
import StaticEdit from './staticEdit';
import StaticCreate from './StaticCreate';

export default {
  list: StaticList,
  icon: StaticIcon,
  edit: StaticEdit,
  create: StaticCreate,
};
