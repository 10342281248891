import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  useDataProvider,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import gql from 'graphql-tag';
import client from '../../providers/client';
import { uploadCouponCodesButton } from './uploadCouponCodesButtonStyles';

const INITIAL_FILE = 0;

const UploadCouponCodesButton = (props) => {
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [fetchingCodes, setFetchingCodes] = useState(false);
  const { recordData } = props;
  const dataProvider = useDataProvider();
  const classes = uploadCouponCodesButton();
  const translate = useTranslate();
  const form = useForm();

  const GET_PROMOTION_CODES = gql`
    query promotion($id: ID!) {
      promotion(id: $id) {
        id
        couponCodesStats {
          total
          distributed
          notDistributed
        }
      }
    }
  `;

  const parentProcessingHandler = (isProcessing) => {
    props.passChildProcessing(isProcessing);
  };

  const parentCodesHandler = (codesData) => {
    props.passChildCodes(codesData);
  };

  const getPromotionCodes = () => {
    setFetchingCodes(true);

    client
      .query({
        query: GET_PROMOTION_CODES,
        fetchPolicy: 'no-cache',
        variables: {
          id: recordData.uuid,
        },
      })
      .then((res) => {
        const { data } = res;
        const { promotion } = data;
        const { couponCodesStats } = promotion;

        form.change('couponCodesStats.total', couponCodesStats.total);
        form.change('couponCodesStats.distributed', couponCodesStats.distributed);
        form.change('couponCodesStats.notDistributed', couponCodesStats.notDistributed);
        parentCodesHandler(couponCodesStats);
        setFetchingCodes(false);
      })
      .catch(() => {
        const couponCodesStats = {
          total: 'ERROR',
          distributed: 'ERROR',
          notDistributed: 'ERROR',
        };
        parentCodesHandler(couponCodesStats);
        setFetchingCodes(false);
      });
  };

  const handleOnChange = () => {
    setLoading(true);
    setError(false);
    parentProcessingHandler(true);
    const uploadedFile = document.getElementById('inputCSV').files[INITIAL_FILE];

    dataProvider.create('coupons', { id: recordData.uuid, file: uploadedFile })
      .then(() => {
        setFile(uploadedFile);
      }).catch((err) => {
        setLoading(false);
        setFile(undefined);
        setError(err?.message);
      });
  };

  const buttonText = () => {
    if (loading) {
      return translate('promotion.coupons.uploading');
    }

    if (fetchingCodes === true) {
      return translate('promotion.coupons.refreshing');
    }

    return translate('promotion.coupons.upload');
  };

  useEffect(() => {
    if (file) {
      setLoading(false);
      parentProcessingHandler(false);
      getPromotionCodes();
    }
  }, [file]);

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          component="label"
          fullWidth
          disabled={loading || fetchingCodes}
        >
          {loading
          || fetchingCodes
            ? (
              <CircularProgress size={20} thickness={1} className={classes.loadingCircle} />
            ) : null}
          {buttonText()}
          <input
            type="file"
            id="inputCSV"
            accept=".csv"
            hidden
            onChange={e => handleOnChange(e)}
          />
        </Button>
        {file
          ? <p><span className={classes.fileLabel}>{translate('promotion.coupons.file')}</span> {file.name}</p> : null }
        {error ? <span className={classes.errorMessage}>{error}</span> : null}
      </div>

    </div>
  );
};


export default UploadCouponCodesButton;
