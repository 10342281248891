import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addField, FieldTitle } from 'ra-core';
import PropTypes from 'prop-types';
import {
  FormHelperText, InputLabel, FormControl, withStyles,
} from '@material-ui/core';
import { InputHelperText } from 'ra-ui-materialui';

const styles = {
  label: {
    position: 'relative',
  },
};

class RichTextEditor extends React.PureComponent {
  handleValueChange(value) {
    let textContent = value;
    const { input: { onChange } } = this.props;
    if (value.match(/^<p><br><\/p>$/)) {
      textContent = null;
    }
    onChange(textContent);
  }

  render() {
    const {
      meta: { touched, error }, helperText,
      label,
      id,
      source,
      resource,
      isRequired,
      classes,
      input: { value },
    } = this.props;
    return (
      <FormControl
        fullWidth
        error={!!(touched && error)}
      >
        {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        )}
        <ReactQuill
          theme="snow"
          value={value}
          onChange={data => this.handleValueChange(data)}
        />
        <FormHelperText
          error={!!error}
        >
          <InputHelperText
            error={error}
            helperText={helperText}
            touched={touched}
          />
        </FormHelperText>
      </FormControl>
    );
  }
}

const RichTextEditorInputWithField = addField(withStyles(styles)(RichTextEditor));

RichTextEditorInputWithField.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

RichTextEditorInputWithField.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default RichTextEditorInputWithField;
