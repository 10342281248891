import React from 'react';
import { Toolbar, SaveButton, DeleteWithConfirmButton } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  override: {
    '&.MuiToolbar-root': {
      justifyContent: 'space-between',
    },
  },
}));

const ProductToolbar = (props) => {
  const classes = useStyles();
  const {
    saving, handleSubmit, isEdit,
  } = props;
  return (
    <Toolbar {...props} className={classes.override}>
      <SaveButton label="Save" disabled={saving} onClick={handleSubmit} />
      {isEdit && <DeleteWithConfirmButton mutationMode="pessimistic" />}
    </Toolbar>
  );
};

export default ProductToolbar;
