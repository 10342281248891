import React from 'react';
import {
  Box, Divider, Grid, Typography,
} from '@material-ui/core';

import {
  TextField,
  RichTextField,
  ImageField,
  useTranslate,
} from 'react-admin';
import moment from 'moment';
import {
  convertMarkDowntoHtml, getDefaultEnvRetailerImage,
} from '../../lib/utils';

const ItemDetail = ({
  title, center, children, ...rest
}) => {
  const align = center ? 'center' : 'left';
  return (
    <Grid item xs={6} {...rest}>
      <Box my={2}>
        <Typography color="primary" align={align}>
          {title}
        </Typography>
        <Box align={align}>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

const RetailerShowLayout = (props) => {
  const {
    record,
    record: {
      description,
    },
  } = props;
  const newProps = {
    ...props,
    record: {
      ...record,
      description: convertMarkDowntoHtml(description),
    },
  };
  const translate = useTranslate();

  return (
    <Box mx={5}>
      <Grid container>
        <ItemDetail
          title={translate('retailers.edit.name')}
          md={12}
        >
          <TextField source="name" {...props} />
        </ItemDetail>
      </Grid>
      <Divider />
      <Grid container>
        <ItemDetail
          title={translate('retailers.edit.description')}
          md={12}
        >
          <RichTextField source="description" {...newProps} />
        </ItemDetail>
      </Grid>
      <Divider />
      <Grid container>
        <ItemDetail
          title={translate('retailers.edit.status')}
          md={12}
        >
          <TextField source="status" {...props} />
        </ItemDetail>
      </Grid>
      <Divider />
      <Grid container>
        <ItemDetail
          title={translate('retailers.edit.logo')}
          md={12}
        >
          <ImageField source="imageUrl" initialValue={getDefaultEnvRetailerImage()} {...props} />
        </ItemDetail>
      </Grid>
      <Divider />
      <Grid container>
        <ItemDetail
          title={translate('retailers.edit.creationDate')}
          md={12}
        >
          <Typography variant="h6">
            {moment(record?.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </ItemDetail>
      </Grid>
    </Box>
  );
};
export default RetailerShowLayout;
