import React, { useState } from 'react';
import {
  DateInput, SimpleForm, downloadCSV, useTranslate, SelectInput,
} from 'react-admin';
import moment from 'moment-timezone';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { gql } from '@apollo/client';
import jsonExport from 'jsonexport/dist';

import { promotionFormStyles } from './promotionFormStyles';
import client from '../../providers/client';
import { parseToLongDate } from '../../lib/utils/date';

const PromotionOrdersReport = () => {
  const t = useTranslate();
  const classes = promotionFormStyles();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateUntil, setDateUntil] = useState(null);
  const [promoType, setPromoType] = useState('all');
  const [orderStatus, setOrderStatus] = useState('all');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(undefined);

  const typeOptions = [
    {
      id: 'SAZ NEXT WITH POINTS',
      name: 'SAZ Next With Points',
    },
    {
      id: 'SAZ NEXT WITHOUT POINTS',
      name: 'SAZ Next Without Points',
    },
    {
      id: 'WITHDRAWAL AT REDEMPTION POINT',
      name: 'Withdrawal at redemption point',
    },
    {
      id: 'HOME DELIVERY',
      name: 'Home delivery',
    },
  ];

  const statusOptions = [
    {
      id: 'REQUESTED',
      name: 'Requested',
    },
    {
      id: 'CANCELLED',
      name: 'Cancelled',
    },
    {
      id: 'REDEEMED',
      name: 'Redeemed',
    },
    {
      id: 'EVALUATED',
      name: 'Evaluated',
    },
  ];

  const ORDERS_QUERY = gql`
    query Orders($filter: OrderFilterInput) {
      orders(filter: $filter) {
        results {
          id
          user {
            username
            name
            lastName
            profile {
              identifier
              address {
                line1
                line2
                administrativeAreaLevel1
                administrativeAreaLevel2
                administrativeAreaLevel3
              }
            }
            telephone
          }
          expirationDate
          requestedDate
          retailer {
            name
          }
          promotion {
            name
            eans {
              value
            }
            promotionType {
              name
            }
          }
          status
        }
      }
    }
  `;

  const ordersExporter = (data) => {
    const parsedExport = data.map((item) => {
      const {
        id, promotion, expirationDate, requestedDate, user, status, retailer,
      } = item;
      const { eans, name: promotionName, promotionType } = promotion;
      const { name: orderPromoType } = promotionType;
      const {
        lastName, name, profile, username, telephone,
      } = user;
      const { address, identifier } = profile;
      const {
        line1, line2, administrativeAreaLevel1, administrativeAreaLevel2, administrativeAreaLevel3,
      } = address;
      const hoursToAdd = 72;

      const getFullAddress = `${line1}${line2 ? `; ${line2}` : ''}; ${administrativeAreaLevel1}; ${administrativeAreaLevel2}; ${administrativeAreaLevel3}`;

      return {
        orderId: id,
        email: username,
        date: parseToLongDate(requestedDate),
        expirationDate: expirationDate ? parseToLongDate(expirationDate) : '',
        availabilityDate: parseToLongDate(moment(requestedDate).add(hoursToAdd, 'hours')),
        status,
        type: orderPromoType,
        name: `${name} ${lastName}`,
        retailer: retailer?.name ?? '',
        identifier,
        telephone,
        address: getFullAddress,
        product: promotionName,
        ean: eans?.[0]?.value,
      };
    });

    jsonExport(parsedExport, {
      rowDelimiter: ';',
      headers: [
        'orderId',
        'email',
        'date',
        'expirationDate',
        'availabilityDate',
        'status',
        'type',
        'name',
        'retailer',
        'identifier',
        'telephone',
        'address',
        'product',
        'ean',
      ],
      rename: [
        'ID de Pedido',
        'Email',
        'Fecha del pedido',
        'Fecha de expiración',
        'Fecha de disponibilidad',
        'Estado',
        'Tipo de promoción',
        'Nombre completo',
        'Punto de canje',
        'Cédula',
        'Teléfono',
        'Dirección',
        'Producto',
        'EAN',
      ],
    }, (err, csv) => {
      const BOM = '\uFEFF';
      const fileName = `OrdersReport_${moment().tz('America/Panama').format('DDMMYYYY_HHmmss')}`;
      downloadCSV(`${BOM} ${csv}`, fileName);
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setMessage(undefined);
    const fromFullDate = `${dateFrom} 00:00:00`;
    const untilFullDate = `${dateUntil} 23:59:59`;

    const from = moment(fromFullDate).utc().format();
    const until = moment(untilFullDate).utc().format();

    client.query({
      query: ORDERS_QUERY,
      variables: {
        filter: {
          createdFrom: from,
          createdUntil: until,
          ...(promoType && promoType !== 'all' ? { promotionType: promoType } : {}),
          ...(orderStatus && orderStatus !== 'all' ? { status: orderStatus } : {}),
        },
      },
    })
      .then((res) => {
        const zero = 0;
        const { data } = res;
        const { orders } = data;
        const { results } = orders;

        if (results.length <= zero) {
          setMessage(t('orders.export.noResults'));
        } else {
          setMessage(t('orders.export.results', { total: results?.length }));
          ordersExporter(results);
        }

        setLoading(false);
      })
      .catch(() => {
        setMessage(t('orders.export.error'));
        setLoading(false);
      });
  };

  return (
    <SimpleForm style={{ width: '100%' }} onSubmit={() => null} toolbar={null}>
      <div style={{ width: '100%', margin: '0 0 20px 0' }}>
        <Typography variant="h1">{t('orders.export.title')}</Typography>
      </div>

      <div className={classes.mainRowGap}>
        <div className={classes.mainRowCol}>
          <SelectInput
            source="promotionType"
            choices={typeOptions}
            optionText="name"
            optionValue="id"
            label="Promotion Type"
            onChange={e => setPromoType(e?.target?.value)}
            allowEmpty
            emptyText="All types"
            emptyValue="all"
            initialValue="all"
          />
        </div>
        <div className={classes.mainRowCol}>
          <SelectInput
            source="orderStatus"
            choices={statusOptions}
            optionText="name"
            optionValue="id"
            label="Order Status"
            onChange={e => setOrderStatus(e?.target?.value)}
            allowEmpty
            emptyText="All status"
            emptyValue="all"
            initialValue="all"
          />
        </div>
      </div>
      <div className={classes.mainRowGap}>
        <div className={classes.mainRowCol}>
          <DateInput
            source="dateFrom"
            type="date"
            label={t('orders.export.labelFrom')}
            value={dateFrom}
            onChange={e => setDateFrom(e.target.value)}
          />
        </div>
        <div className={classes.mainRowCol}>
          <DateInput
            source="dateUntil"
            type="date"
            label={t('orders.export.labelUntil')}
            value={dateUntil}
            onChange={e => setDateUntil(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.mainRowGap}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!dateFrom || !dateUntil || loading}
        >
          {loading ? t('orders.export.loading') : t('orders.export.request')}
        </Button>
      </div>
      {message !== undefined && typeof message === 'string' && (
        <div className={classes.mainRowGap} style={{ width: '100%' }}>
          <Typography variant="body1">{message}</Typography>
        </div>
      )}
    </SimpleForm>
  );
};

export default PromotionOrdersReport;
