import React from 'react';
import {
  Show,
  TopToolbar,
  EditButton,
  ListButton,
  useTranslate,
  Loading,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RetailerTitle from './RetailerTitle';
import RetailerShowLayout from './RetailerShowLayout';
import { TabbedLayout, Tab } from '../../lib/components';
import PromotionReferenceList from './PromotionReferenceList';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const RetailerShowActions = ({
  basePath, data, resource,
}) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);
const RetailerShow = ({ permissions, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { id } = props;
  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab label={translate('retailers.tabs.detail')}>
          <Show
            title={<RetailerTitle />}
            actions={<RetailerShowActions permissions={permissions} />}
            {...props}
          >
            <RetailerShowLayout />
          </Show>
        </Tab>
        <Tab label={translate('retailers.tabs.promotions')} path="promotions">
          {
          !id ? <Loading />
            : <PromotionReferenceList {...props} id={id} />
          }
        </Tab>
      </TabbedLayout>
    </div>
  );
};
export default RetailerShow;
