import React, { useEffect, useState } from 'react';
import {
  useDataProvider, useNotify, useRedirect, Button,
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { useFormState } from 'react-final-form';
import { parseEditProfileForm } from '../../../lib/utils/profile';


const EditBtn = ({
  form, errorHomeCom, customLoad, customProfile,
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { errors } = useFormState();
  const [enabledBtn, setEnabledBtn] = useState(false);
  const zero = 0;
  useEffect(() => {
    const noError = Object.entries(errors).length === zero;
    const isEnabled = noError && !errorHomeCom;
    setEnabledBtn(isEnabled);
  }, [errors, errorHomeCom]);

  const edit = () => {
    customLoad(true);
    return enabledBtn && dataProvider
      .update('profiles', { data: { ...parseEditProfileForm(form) } })
      .then((res) => {
        const { userId } = form;
        customLoad(false);
        redirect(`/users/${userId}/profile`);
        customProfile(res?.data);
        notify('profile updated');
      })
      .catch((error) => {
        customLoad(false);
        notify(`Error: ${error.message}`, 'warning');
      });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      label="Edit profile"
      onClick={edit}
      disabled={!enabledBtn}
      startIcon={<SaveIcon />}
    />
  );
};
export default EditBtn;
