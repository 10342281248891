import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  valueBox: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function PromotionStatusRollover({ record = {} }) {
  const classes = useStyles();
  const { code, status } = record?.status || {};
  return (
    <Tooltip
      arrow
      placement="right"
      title={`${code}. ${status}`}
    >
      <Box component="span" width="100%" className={classes.valueBox}>{code}</Box>
    </Tooltip>
  );
}
