import { makeStyles } from '@material-ui/core/styles';

export const promotionShowStyles = makeStyles(theme => ({
  formControlFields: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  helpLabel: {
    color: 'black',
    fontSize: 14,
  },
  helpContent: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  formControl: {
    width: '100%',
    margin: '0 0 20px 0',
    marginBottom: '20px',
    padding: '10px 50px 50px 20px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  columnLeft: {
    width: '50%',
    padding: '20px',
    paddingRight: '50px',
  },
  columnRight: {
    width: '50%',
    padding: '20px',
  },
  normalBox: {
    height: '65px',
    display: 'flex',
    padding: '10px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  normalBoxLeft: {
    width: '50%',
    paddingRight: '10px',
  },
  normalBoxRight: {
    width: '50%',
    paddingLeft: '10px',
  },
  eanBox: {
    height: '200px',
    padding: '10px',
  },
  bigBox: {
    height: '145px',
    padding: '10px',
  },
  imageBox: {
    height: '270px',
    padding: '10px',
  },
  numInput: {
    width: '100%',
  },
  stockTitle: {
    backgroundColor: '#e4e8f2',
    width: '100%',
    fontSize: '1.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    margin: '10px 0 10px 0',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
}));

export default promotionShowStyles;
