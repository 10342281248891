import React from 'react';
import {
  Create, SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { convertTextToNormal, getEdition, getEnv } from '../../lib/utils';


const StaticCreate = ({ permissions, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const uuid = uuidv4();
  const platform = getEdition();
  const env = getEnv();

  const handleSave = async (values) => {
    try {
      const response = await fetch(`https://monavis-pruebas.shopadvizor.net/scripts/pxy.php?env=${env}&platform=${platform}&file=index.json`);
      if (!response.ok) {
        throw new Error(`Error fetching the file: ${response.statusText}`);
      }
      const textResponse = await response.text();
      const parsedResponse = JSON.parse(textResponse).response;
      const parsedEntries = JSON.parse(parsedResponse);
      const existingEntries = Array.isArray(parsedEntries) ? parsedEntries : [];
      const identity = JSON.parse(localStorage.getItem('auth'));
      const newEntry = {
        id: uuid,
        title: values.title,
        alias: convertTextToNormal(values.alias),
        created: new Date().toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }),
        author: `${identity?.name} ${identity?.lastName}`,
        status: 'REJECTED',
      };

      existingEntries.push(newEntry);
      const jsonString = JSON.stringify(existingEntries);
      const jsonBlob = new Blob([jsonString], { type: 'application/json;charset=UTF-8' });
      const htmlBlob = new Blob([''], { type: 'application/json;charset=UTF-8' });
      const updateIndex = await dataProvider.create('static', {
        path: `static-content/${platform}/index.json`,
        file: jsonBlob,
      });
      const createContent = await dataProvider.create('static', {
        path: `static-content/${platform}/${uuid}.html`,
        file: htmlBlob,
      });

      const uploadStatus = (updateIndex?.data?.isUploaded && createContent.data?.isUploaded);

      if (uploadStatus) {
        notify(`Content "${values.title}" created successfully`, 'success');
        redirect(`/static/${uuid}`);
      }
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    }
  };

  return (
    <Create {...props}>
      <SimpleForm save={handleSave}>
        <Box style={{ width: '50%' }}>
          <Typography style={{ width: '100%' }} variant="body1">Choose a title for the content</Typography>
          <TextInput fullWidth source="title" />
        </Box>
        <Box style={{ width: '50%' }}>
          <Typography style={{ width: '100%' }} variant="body1">Type an alias without spaces and special characters.</Typography>
          <Typography style={{ width: '100%' }} variant="body1">It will be used in url: .../article/your-alias (i.e. /article/content-test)</Typography>
          <TextInput fullWidth source="alias" />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default StaticCreate;
