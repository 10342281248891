import React, { useState, useEffect } from 'react';
import { useTranslate, useMutation, useNotify } from 'react-admin';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { USER_BUSINESS_DOMAIN_NAME } from './buildQueryUsers';
import { generateChecksum } from '../../lib/utils';

import ACTIONS from './utils';

import userPointsFormStyles from './userPointsFormStyles';


const MIN_POINTS = 0;

const SOURCE = 'point-saz';


const renderPointsFormTitle = (translate, type) => {
  if (type === ACTIONS.ADD) {
    return translate('points.actions.addPoints');
  }

  return translate('points.actions.subtractPoints');
};

const UserPointsForm = ({
  action,
  isOpen,
  switchDialog,
  record,
}) => {
  const [concept, setConcept] = useState(null);
  const [points, setPoints] = useState(null);
  const [formParams, setFormParams] = useState(null);
  const [onCompleted, setOnCompleted] = useState(null);
  const notify = useNotify();
  const translate = useTranslate();
  const classes = userPointsFormStyles();

  const [addPointsAction, { loading, data, error }] = useMutation({
    type: 'update',
    resource: 'points',
    payload: { ...formParams },
  });

  useEffect(() => {
    if (formParams) {
      addPointsAction();
    }
  }, [formParams]);

  useEffect(() => {
    if (data) {
      setOnCompleted(data);
    }
  }, [data]);

  useEffect(() => {
    if (data && onCompleted) {
      switchDialog(false);
      setConcept(null);
      setPoints(null);
      setOnCompleted(null);
      if (action === ACTIONS.ADD) {
        notify(translate('points.actions.pointsAdded'));
      } else if (action === ACTIONS.SUBSTRACT) {
        notify(translate('points.actions.pointsSubtracted'));
      }
    }
  }, [onCompleted]);

  useEffect(() => {
    if (error) {
      notify(`Sorry, there was an error: ${error} `, 'warning');
    }
  }, [error]);

  const handleConfirm = () => {
    const checksumObject = {
      business_domain: USER_BUSINESS_DOMAIN_NAME,
      id: record.id,
      action: `backoffice ${action} points at ${moment().format()}`,
      user_id: record.id,
    };

    setFormParams({
      action,
      checksum: generateChecksum(checksumObject),
      userId: record.id,
      source: SOURCE,
      amount: points,
      concept,
    });
  };

  const handleCancel = () => {
    switchDialog(false);
    setConcept(null);
    setPoints(null);
  };

  const checkDisabled = () => concept === null || points === null;

  const addConcept = (e) => {
    setConcept(e.target.value);

    if (e.target.value === '') {
      setConcept(null);
    }
  };

  const addPoints = (e) => {
    setPoints(Math.abs(e.target.value));

    if (e.target.value === '') {
      setPoints(null);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => switchDialog(false)}
      aria-labelledby="form-dialog-title"
      data-cy="user-points-form"
    >
      <DialogTitle id="form-dialog-title">{renderPointsFormTitle(translate, action)}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="concept"
          label={translate('points.actions.concept')}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => addConcept(e)}
        />
        <TextField
          id="amount"
          label={translate('points.actions.amount')}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={points === MIN_POINTS ? null : points}
          onChange={e => addPoints(e)}
          InputProps={{ inputProps: { min: MIN_POINTS } }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCancel()}
          color="primary"
          disabled={loading}
        >
          {translate('points.actions.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => handleConfirm()}
          onClose={() => switchDialog(false)}
          disabled={checkDisabled() || loading}
          data-cy="confirm-points-button"
        >
          {loading ? (
            <CircularProgress
              size={15}
              thickness={2}
              className={classes.loading}
            />
          ) : null}
          {translate('points.actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserPointsForm;
