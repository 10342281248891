import { makeStyles } from '@material-ui/core/styles';

export const createSurveyButton = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    margin: '6px 0 0 14px',
  },
  check: {
    color: 'green',
    margin: '3px 0 0 6px',
  },
  loadingCircle: {
    padding: '0 10px 0 10px',
  },
}));
export default createSurveyButton;
