import {
  BooleanInput,
  Button,
  DateInput,
  Edit,
  FormDataConsumer, SaveButton,
  SimpleForm,
  TextInput, Toolbar,
  useEditController,
  useTranslate,
} from 'react-admin';
import React, { useState } from 'react';
import { useNotify, useRefresh } from 'ra-core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

import gql from 'graphql-tag';
import UserValidation from './UserValidation';
import UserTitle from './UserTitle';

import client from '../../providers/client';
import { ONE } from '../promotions/constants';
import DeleteBtn from './buttons/DeleteBtn';

const useStyles = makeStyles(selectedTheme => ({
  twoColumns: {
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0 20px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  p: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: '0 10px',
    fontFamily: selectedTheme.typography.fontFamily,
  },
}));
const UserEditToolbar = props => (
  <Toolbar {...props} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    <SaveButton
      label="Edit user"
      redirect="edit"
      submitOnEnter
      data-testid="user-edit-submit"
    />
    <DeleteBtn customLoad={() => {}} />
  </Toolbar>
);

const UserDetailTab = (props) => {
  const { record } = useEditController(props);
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = (basePath, id, data) => `${basePath}/${data.id}`;
  const refresh = useRefresh();
  const notify = useNotify();
  const [verifying, setVerifying] = useState(false);

  const verifyTelephoneMutation = gql`
    mutation VerifyTelephoneAsAdmin($input: VerifyTelephoneAsAdminInput!) {
      verifyTelephoneAsAdmin(input: $input)
    }
  `;

  const verifyPhone = () => {
    setVerifying(true);

    client.mutate({
      mutation: verifyTelephoneMutation,
      variables: {
        input: {
          userId: props.id,
        },
      },
    })
      .then(() => {
        setVerifying(false);
        notify('Telephone verified successfully', 'success');
        refresh();
      })
      .catch((err) => {
        setVerifying(false);
        let errorMessage = err?.message;
        const split = errorMessage.split('failed:');

        if (split[ONE]) {
          errorMessage = split[ONE];
        }

        notify(errorMessage, 'error');
      });
  };

  const onSuccessActions = () => {
    notify('User updated successfully', 'success');
    refresh();
  };

  return (
    <Edit mutationMode="pessimistic" onSuccess={onSuccessActions} title={<UserTitle />} {...props}>
      <SimpleForm redirect={redirect} toolbar={<UserEditToolbar />}>
        <TextInput
          source="username"
          validate={UserValidation.validateUsername}
          resettable
          label={translate('user.edit.username')}
        />
        <TextInput
          source="name"
          validate={UserValidation.validateName}
          resettable
          label={translate('user.edit.name')}
        />
        <TextInput
          source="lastName"
          validate={UserValidation.validateLastName}
          resettable
          label={translate('user.edit.lastname')}
        />
        <BooleanInput
          source="active"
          label={translate('user.edit.activated')}
        />
        <BooleanInput
          source="isAdmin"
          label={translate('user.edit.isAdmin')}
        />
        <TextInput
          source="telephone"
          resettable
          label={translate('user.edit.telephone')}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <div className={classes.twoColumns}>
              <div className={classes.p}>{formData.telephoneVerifiedAt ? (
                <>
                  <CheckCircleIcon />
                  {translate('user.edit.verifiedYes')}
                </>
              ) : (
                <>
                  <CancelIcon />
                  {translate('user.edit.verifiedNo')}
                </>
              )}
              </div>
              <Button
                label={verifying ? 'Veryfing...' : 'Verify now'}
                variant="contained"
                onClick={() => verifyPhone()}
                disabled={verifying || formData.telephoneVerifiedAt || !record?.telephone}
              />
            </div>
          )}
        </FormDataConsumer>
        <BooleanInput
          source="conditions"
          disabled
          label={translate('user.edit.conditions')}
        />
        <DateInput
          source="createdAt"
          disabled
          label={translate('user.edit.createdAt')}
        />
        <DateInput
          source="updatedAt"
          disabled
          label={translate('user.edit.updatedAt')}
        />
        <DateInput
          source="activatedAt"
          disabled
          label={translate('user.edit.activatedAt')}
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserDetailTab;
