import {
  downloadCSV,
} from 'react-admin';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';

import { PROMOTIONS_RETAILER } from './constants';

const exporter = (promotions) => {
  moment.locale('fr',
    {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
    });
  const promotionExporter = promotions.map((promotion) => {
    const {
      key, name, startDate, status,
    } = promotion;
    return {
      key,
      name,
      startDate: moment(startDate).isValid() ? moment(startDate).format('L') : 'Unknown date',
      status: `${status.code}. ${status.status}`,
    };
  });

  jsonExport(promotionExporter, {
    headers: [
      'key', 'name', 'startDate', 'status',
    ],
    rename: [
      'Key', 'Name', 'Start Date', 'Status',
    ],
  }, (err, csv) => {
    downloadCSV(csv?.replace(/,/g, ';'), PROMOTIONS_RETAILER); // download as 'promotions.csv` file
  });
};

export default exporter;
