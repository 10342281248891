import React, { useEffect, useState } from 'react';
import {
  Button,
  Create,
  required,
  SelectInput,
  SimpleForm,
  useDataProvider,
  useNotify,
  useRedirect,
  Toolbar,
} from 'react-admin';
import { Box, CircularProgress } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';

const PromotionCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const parseTypesDataToOptions = (data) => {
    const options = [];
    data.data.forEach(option => options.push({ id: option.id, name: option.name }));

    setSelectOptions(options);
    setLoading(false);
  };

  const handleChange = (event) => {
    setSelectedOption(event?.target?.value);
  };

  const handleSubmit = () => {
    if (selectedOption) {
      setCreateLoading(true);
      dataProvider.create('promotions', {
        input: {
          promotionTypeId: selectedOption,
        },
      })
        .then((res) => {
          setCreateLoading(false);
          const { data } = res;
          const { key } = data;
          redirect(`/promotions/${key}`);
        })
        .catch((error) => {
          notify(`Error: ${error.message}`, 'warning');
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    dataProvider.getList('promotionTypes')
      .then(data => parseTypesDataToOptions(data));
  }, []);

  return (
    <Create {...props} redirect={false}>
      <SimpleForm toolbar={false}>
        <SelectInput
          source="promotionType"
          label="Promotion Type"
          choices={selectOptions || []}
          validate={required()}
          optionText="name"
          optionValue="id"
          onChange={handleChange}
          loading={loading}
        />
        <Box display="flex" width="100%">
          <Toolbar style={{ width: '100%' }}>
            <Button
              label="Create"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              startIcon={createLoading
                ? <CircularProgress size={20} thickness={1} /> : <ContentAdd />}
              disabled={!selectedOption || createLoading}
            />
          </Toolbar>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default PromotionCreate;
