import React from 'react';

import { Datagrid, List, TextField } from 'react-admin';

const StaticList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="author" />
      <TextField
        source="created"
      />
    </Datagrid>
  </List>
);

export default StaticList;
