import React from 'react';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Box, makeStyles } from '@material-ui/core';

const ONE = 1;
const useStyles = makeStyles(theme => ({
  itemWrapper: {
    padding: theme.spacing(ONE),
  },
  valueBox: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  eanItem: {
    fontSize: '0.875rem',
  },
}));

export default function EANListPopover({ record = {} }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const initialValue = record && record.eans && record.eans.length ? record.eans[0].value : null;

  return (
    <Box>
      <Box component="span" width="100%" className={classes.valueBox} onClick={handleClick}> {initialValue}</Box>
      <Popover
        id="ean-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.itemWrapper}>
          {record.eans.map(item => (
            <Typography className={classes.eanItem} key={item.value}>{item.value}</Typography>
          ))}
        </Box>
      </Popover>
    </Box>
  );
}
