import TurndownService from 'turndown';
import marked from 'marked';
import _ from 'lodash';

marked.setOptions({
  headerIds: false,
});


export const filterEans = eans => eans.map(ean => ean && ean.value)
  .filter(Boolean);

export const convertHtmlToMarkdown = (data) => {
  if (!data) {
    return null;
  }
  const turndownService = new TurndownService();
  return turndownService.turndown(data);
};
export const convertMarkDowntoHtml = (data) => {
  if (!data) {
    return null;
  }

  return marked(data);
};

export const addAttributesToLink = (link) => {
  const href = /href=".+?"+/gmi;
  const hrefWithClosingTag = /href=".+?">+/gmi;
  let linkString = link;
  const links = linkString.match(href);
  if (links) {
    links.forEach((item) => {
      const addAttributes = `${item} rel="noopener noreferrer" target="_blank">`;
      linkString = linkString.replace(hrefWithClosingTag, addAttributes);
    });
  }
  return linkString;
};


export const buildUserOrderInput = (sort) => {
  const { order, field } = sort;
  const userOrderFilter = {};

  _.assignIn(userOrderFilter, { field });
  _.assignIn(userOrderFilter, { order });

  return userOrderFilter;
};


export const buildCommentsOrderInput = (sort) => {
  const { order } = sort;

  return sort.field === 'rating' ? { field: 'value', order } : sort;
};

export const isProd = () => {
  // REACT_APP_GRAPHQL_CURRENT_ENV value in production enviroment is equal to prepro,
  // so we need to check production enviroment by host.
  const INDEX_OF_VALUE = -1;
  const PRODUCTION_HOST = 'public.shopadvizor.com';
  const PRODUCTION_HOST_AUX = 'shopadvizor.com.pa';

  return window.location.host.indexOf(PRODUCTION_HOST) !== INDEX_OF_VALUE
  || window.location.host.indexOf(PRODUCTION_HOST_AUX) !== INDEX_OF_VALUE;
};

export const isDev = () => {
  const INDEX_OF_VALUE = -1;
  const HOST = 'develop-public.shopadvizor.net';
  return window.location.host.indexOf(HOST) !== INDEX_OF_VALUE;
};

export const isPre = () => {
  const INDEX_OF_VALUE = -1;
  const HOST = 'prepro-public.shopadvizor.net';
  return window.location.host.indexOf(HOST) !== INDEX_OF_VALUE;
};


export const GRAPHQL_API_URL = () => {
  if (isProd()) {
    return process.env.REACT_APP_GRAPHQL_API_PROD_URL;
  }
  if (isPre()) {
    return process.env.REACT_APP_GRAPHQL_API_PRE_URL;
  }

  return process.env.REACT_APP_GRAPHQL_API_DEV_URL;
};


export const OAUTH_API_URL = () => {
  if (isProd()) {
    return process.env.REACT_APP_AUTH_URL_PROD;
  }
  if (isPre()) {
    return process.env.REACT_APP_AUTH_URL_PRE;
  }

  return process.env.REACT_APP_AUTH_URL_DEV;
};

export const GET_CLIENT_SECRET = () => {
  if (isProd()) {
    return process.env.REACT_APP_CLIENT_SECRET_PROD;
  }
  if (isPre()) {
    return process.env.REACT_APP_CLIENT_SECRET_PRE;
  }

  return process.env.REACT_APP_CLIENT_SECRET;
};

export const RESET_PASSWORD_URL = () => {
  const RESET_PASSWORD_URL_PRODUCTION = 'https://panelist-spa.public.shopadvizor.com/reset';
  if (isProd()) {
    return RESET_PASSWORD_URL_PRODUCTION;
  }
  if (isPre()) {
    return process.env.REACT_APP_RESET_PASSWORD_URL_PRE;
  }
  return process.env.REACT_APP_RESET_PASSWORD_URL_DEV;
};

export const GET_SYNDICATION_URL = () => {
  const SYNDICATION_URL_PROD = 'https://review-service.public.shopadvizor.com/clients/';

  if (isProd()) {
    return SYNDICATION_URL_PROD;
  }
  if (isPre()) {
    return process.env.REACT_APP_SYNDICATION_URL_PRE;
  }

  return process.env.REACT_APP_SYNDICATION_URL_DEV;
};

export const parseCreatePolicies = (formInput) => {
  const policies = [];
  const { userId } = formInput;
  const policiesOptions = [
    {
      code: 'platformCommunications',
      name: 'Platform Communications',
    },
    {
      code: 'retailerCommunications',
      name: 'Retailer Communications',
    },
    {
      code: 'dataLinkWithSAZ',
      name: 'Link profile with ShopAdvizor',
    },
    {
      code: 'thirdPartyCommunications',
      name: 'Third Party Communications',
    },
  ];
  const allPolicies = ['platformCommunications', 'retailerCommunications', 'dataLinkWithSAZ', 'thirdPartyCommunications'];

  allPolicies.forEach((policy) => {
    if (_.has(formInput, policy)) {
      policies.push(policiesOptions.find(item => item.code === policy));
    }
  });
  return {
    policies,
    userId,
  };
};
export const parseEditPolicies = (formInput) => {
  const policies = [];
  const policiesOptions = [
    {
      code: 'platformCommunications',
      name: 'Platform Communications',
    },
    {
      code: 'retailerCommunications',
      name: 'Retailer Communications',
    },
    {
      code: 'dataLinkWithSAZ',
      name: 'Link profile with ShopAdvizor',
    },
    {
      code: 'thirdPartyCommunications',
      name: 'Third Party Communications',
    },
  ];
  const { userId, id } = formInput;
  const allPolicies = ['platformCommunications', 'retailerCommunications', 'dataLinkWithSAZ', 'thirdPartyCommunications'];

  allPolicies.forEach((policy) => {
    if (formInput[policy] === true) {
      policies.push(policiesOptions.find(item => item.code === policy));
    }
  });
  return {
    policies,
    userId,
    id,
  };
};

export const parseEditProfile = (formInput) => {
  const policies = [];
  const petsForGraphQl = [];
  const policiesOptions = [
    {
      code: 'platformCommunications',
      name: 'Platform Communications',
    },
    {
      code: 'retailerCommunications',
      name: 'Retailer Communications',
    },
    {
      code: 'dataLinkWithSAZ',
      name: 'Link profile with ShopAdvizor',
    },
    {
      code: 'thirdPartyCommunications',
      name: 'Third Party Communications',
    },
  ];
  const {
    userId,
    id,
    gender,
    birthDate,
    address,
    preference,
    pets,
    numberOfHomeComponents,
    partner,
    educationCode,
    phoneNumber,
    children,
  } = formInput;
  const allPolicies = ['platformCommunications', 'retailerCommunications', 'dataLinkWithSAZ', 'thirdPartyCommunications'];

  allPolicies.forEach((policy) => {
    if (formInput[policy] === true) {
      policies.push(policiesOptions.find(item => item.code === policy));
    }
  });
  pets.forEach((pet) => {
    petsForGraphQl.push({
      type: pet,
    });
  });
  return {
    policies,
    userId,
    id,
    gender,
    birthDate,
    address,
    preference,
    partner: _.omit(partner, '__typename'),
    numberOfHomeComponents,
    pets: petsForGraphQl,
    children,
    phoneNumber,
    educationLevel: {
      code: educationCode,
    },
  };
};

export const parseCreateProfile = (formInput) => {
  const policies = [];
  const petsForGraphQl = [];
  const policiesOptions = [
    {
      code: 'platformCommunications',
      name: 'Platform Communications',
    },
    {
      code: 'retailerCommunications',
      name: 'Retailer Communications',
    },
    {
      code: 'dataLinkWithSAZ',
      name: 'Link profile with ShopAdvizor',
    },
    {
      code: 'thirdPartyCommunications',
      name: 'Third Party Communications',
    },
  ];
  const {
    userId,
    gender,
    birthDate,
    address,
    preference,
    pets,
    numberOfHomeComponents,
    partner,
    children,
    educationCode,
    phoneNumber,
  } = formInput;
  const allPolicies = ['platformCommunications', 'retailerCommunications', 'dataLinkWithSAZ', 'thirdPartyCommunications'];

  allPolicies.forEach((policy) => {
    if (formInput[policy] === true) {
      policies.push(policiesOptions.find(item => item.code === policy));
    }
  });
  if (pets) {
    pets.forEach((pet) => {
      petsForGraphQl.push({
        type: pet,
      });
    });
  }
  return {
    policies,
    userId,
    gender,
    birthDate,
    address,
    preference,
    partner: _.omit(partner, '__typename'),
    numberOfHomeComponents,
    phoneNumber,
    pets: petsForGraphQl,
    children,
    educationLevel: {
      code: educationCode,
    },
  };
};

export const PIRATE_SURVEY_URL = () => {
  const PIRATE_SURVEY_SERVICE_PRODUCTION = 'https://promotion-service.public.shopadvizor.com/legacy';

  if (isProd()) {
    return PIRATE_SURVEY_SERVICE_PRODUCTION;
  }

  if (isPre()) {
    return process.env.REACT_APP_PIRATE_SURVEY_SERVICE_PRE;
  }

  return process.env.REACT_APP_PIRATE_SURVEY_SERVICE_DEV;
};

export const generateChecksum = (checksumObject, base = 'base64') => {
  const objJsonStr = JSON.stringify(checksumObject);

  return Buffer.from(objJsonStr).toString(base);
};

export const CSV_TEMPLATE_URL = () => {
  const CSV_TEMPLATE_URL_PRODUCTION = 'https://storage.googleapis.com/saz-develop-sandbox-public/production/product-service/template_upload_products.xlsx';
  const CSV_TEMPLATE_URL_DEV = 'https://storage.googleapis.com/saz-develop-sandbox-public/develop/product-service/template_upload_products.xlsx';
  const CSV_TEMPLATE_URL_PRE = 'https://storage.googleapis.com/saz-develop-sandbox-public/prepro/product-service/template_upload_products.xlsx';
  if (isProd()) {
    return CSV_TEMPLATE_URL_PRODUCTION;
  }
  if (isPre()) {
    return CSV_TEMPLATE_URL_PRE;
  }
  return CSV_TEMPLATE_URL_DEV;
};

export const NOTIFICATION_DELAY = 15000;

export const integerToOneDecimalFloat = (number) => {
  if (!number) {
    return null;
  }

  const decimals = 1;

  return number.toFixed(decimals);
};

export const PLATFORMS = {
  SAZ: 'saz',
  WALMART: 'walmart',
  MALRG: 'monavis',
};
export const COUNTRIES = {
  MX: 'MX',
  FR: 'FR',
  ES: 'ES',
};
export const ALL_COUNTRIES = [COUNTRIES.MX, COUNTRIES.FR, COUNTRIES.ES];
export const ALL_PLATFORMS = [PLATFORMS.SAZ, PLATFORMS.WALMART, PLATFORMS.MALRG];
export const ALL_PLATFORMS_USER = [PLATFORMS.SAZ, PLATFORMS.WALMART];
export const ALL_COUNTRIES_USER = [COUNTRIES.MX, COUNTRIES.FR];

export const filterMixedCommentOptions = [
  { id: `${PLATFORMS.WALMART}/${COUNTRIES.MX}`, name: 'Walmart MX' },
  { id: `${PLATFORMS.SAZ}/${COUNTRIES.FR}`, name: 'SAZ FR' },
  { id: `${PLATFORMS.MALRG}/${COUNTRIES.FR}`, name: 'MonAvis FR' },
  { id: `${PLATFORMS.SAZ}/${COUNTRIES.ES}`, name: 'SAZ ES' },
];

export const filterMixedUserOptions = [
  { id: `${PLATFORMS.WALMART}/${COUNTRIES.MX}`, name: 'Walmart MX' },
  { id: `${PLATFORMS.SAZ}/${COUNTRIES.FR}`, name: 'SAZ FR' },
];

export const parseFilterMixed = (filter) => {
  const parts = filter.split('/');

  return {
    platform: parts[0],
    country: parts[1],
  };
};
const parseCheckboxGroupBooleanInput = (input) => {
  const booleanSingleValue = 1;

  if ((_.isArray(input)) && (input.length === booleanSingleValue)) {
    return _.head(input);
  }

  return undefined;
};
export const buildUserFilterInput = (filter) => {
  const {
    active, activatedAt, username,
  } = filter;
  const userInputFilter = {};

  const parseDateInput = date => date;
  _.assign(userInputFilter, { active: parseCheckboxGroupBooleanInput(active) });
  _.assign(userInputFilter, { activatedAt: parseDateInput(activatedAt) });
  _.assign(userInputFilter, { username });


  return userInputFilter;
};

export const buildRetailersFilterInput = (filter) => {
  const {
    active, name,
  } = filter;
  const InputFilter = {};

  _.assign(InputFilter, { name });
  _.assign(InputFilter, { active: parseCheckboxGroupBooleanInput(active) });

  return InputFilter;
};

export const getDefaultEnvRetailerImage = () => {
  if (isProd()) {
    return 'https://storage.googleapis.com/saz-catalog-production/defaultretailerimage.png';
  }

  if (isPre()) {
    return 'https://storage.googleapis.com/saz-catalog-prepro/defaultretailerimage.png';
  }

  return 'https://storage.googleapis.com/saz-catalog-develop/defaultretailerimage.png';
};
export const getEnv = () => {
  if (isProd()) {
    return 'production';
  }
  if (isPre()) {
    return 'prepro';
  }

  return 'develop';
};
export const getEdition = () => 'saz-pa';

export const convertTextToNormal = text => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
