import React from 'react';

import {
  Datagrid,
  useTranslate,
  ReferenceManyField,
  TextField,
  DateField,
  Pagination,
  useListController,
  TopToolbar,
  ExportButton,
  ListContextProvider,
} from 'react-admin';
import { makeStyles, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { EmptyList } from '../../lib/components';
import exporter from './PromotionExporter';
import PromotionStatusRollover from '../promotions/PromotionStatusRollover';

const ONE = 1;
const TWO = 2;

const useStyles = makeStyles(
  theme => ({
    content: {
      paddingTop: theme.spacing(ONE),
      paddingLeft: theme.spacing(TWO),
      paddingRight: theme.spacing(TWO),
    },
    link: {
      textDecoration: 'none',
      color: 'blue',
      width: '100%',
      display: 'inline-block',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'PromotionReferenceList' },
);


const LinkTextField = ({
  basePath, record = {}, source, addLabel, ...rest
}) => {
  const classes = useStyles();
  const to = `/promotions/${record?.key}`;
  return (
    <TextField
      className={classes.link}
      source={source}
      record={record}
      component={Link}
      to={to}
      {...rest}
    />
  );
};


const PromotionList = (props) => {
  const translate = useTranslate();
  const listContext = useListController(props);

  const newListContext = { ...listContext, data: listContext.data };

  return (
    <ListContextProvider
      value={{ ...newListContext }}
    >
      <TopToolbar>
        <Toolbar>
          <ExportButton
            resource={listContext.resource}
            sort={listContext.currentSort}
            filterValues={listContext.filterValues}
            maxResults={null}
            exporter={exporter}
            data-testid="export-orders-button"
          />
        </Toolbar>
      </TopToolbar>
      <Datagrid optimized>
        <LinkTextField
          source="key"
          basePath="promotions"
          label={translate('promotion.list.promotionKey')}
          sortable={false}
        />
        <LinkTextField
          source="name"
          basePath="promotions"
          label={translate('promotion.list.promotionName')}
          sortable={false}
        />
        <DateField
          source="startDate"
          locales="fr-FR"
          label={translate('promotion.list.startDate')}
          sortable={false}
        />
        <PromotionStatusRollover label={translate('promotion.list.state')} width="3%" />
      </Datagrid>
      <Pagination {...listContext} />
    </ListContextProvider>
  );
};

const PromotionReferenceList = (props) => {
  const {
    id, ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.content}>
      <ReferenceManyField
        key={id}
        reference="promotions"
        target="id"
        filter={{
          retailers: [id],
        }}
        sort={{
          field: 'startDate',
          order: 'DESC',
        }}
        empty={<EmptyList />}
        {...rest}
      >
        <PromotionList
          {...props}
        />
      </ReferenceManyField>
    </div>
  );
};
export default PromotionReferenceList;
