import React from 'react';
import {
  Box, Chip, Grid, Typography,
} from '@material-ui/core';

import {
  DateField,
  TextField,
  RichTextField,
  ImageField,
  useTranslate,
} from 'react-admin';
import {
  convertMarkDowntoHtml,
} from '../../lib/utils';
import ColorField from './ColorField';

import { promotionShowStyles } from './promotionShowStyles';

const ONE = 1;

const SmallOutlinedChip = ({ label }) => (
  <Box mx={1} display="inline-block">
    <Chip variant="outlined" size="small" label={label} />
  </Box>
);

const EanList = ({ eans }) => {
  if (!eans) {
    return null;
  }
  return (
    eans.map(item => (
      <SmallOutlinedChip key={item} label={item.value} />
    ))
  );
};


const RetailerList = ({ retailers }) => {
  if (!retailers) {
    return null;
  }
  return (
    retailers.map(item => (
      <SmallOutlinedChip key={item.id} label={item.name} />
    ))
  );
};

const ItemDetail = ({
  title, center, children, ...rest
}) => {
  const align = center ? 'center' : 'left';
  return (
    <Grid item xs={6} {...rest}>
      <Box my={2}>
        <Typography color="primary" align={align}>
          {title}
        </Typography>
        <Box align={align}>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

const PromotionShowLayout = (props) => {
  const {
    record,
    record: {
      status, eans, legalText, description, retailers,
    },
  } = props;

  const translate = useTranslate();
  const classes = promotionShowStyles();

  const newProps = {
    ...props,
    record: {
      ...record,
      description: convertMarkDowntoHtml(description),
      legalText: convertMarkDowntoHtml(legalText),
    },
  };

  const promoEan = Array.isArray(eans)
    ? <EanList eans={eans} /> : '';
  const promoStatus = `${status?.code}. ${status?.status}`;

  const promoRetailers = Array.isArray(retailers)
    ? <RetailerList retailers={retailers} /> : '';

  return (
    <Box className={classes.form}>
      <div className={classes.columnLeft}>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Name"
          >
            <TextField source="name" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.eanBox}>
          <ItemDetail
            title={eans && eans.length > ONE ? "EAN'S" : 'EAN'}
          >
            {promoEan}
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Coupon code"
            md={12}
          >
            <TextField source="couponCode" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <div className={classes.normalBoxLeft}>
            <ItemDetail
              title="Type"
              md={12}
            >
              <TextField source="type" {...props} />
            </ItemDetail>
          </div>
          <div className={classes.normalBoxRight}>
            <ItemDetail
              title="Value"
              md={12}
            >
              <TextField source="value" {...props} />
            </ItemDetail>
          </div>
        </div>
        <div className={classes.normalBox}>
          <div className={classes.normalBoxLeft}>
            <ItemDetail
              title={translate('promotion.edit.points')}
              md={12}
            >
              <TextField source="promotionStock.initialStock" {...props} />
            </ItemDetail>
          </div>
          <div className={classes.normalBoxRight}>
            <ItemDetail
              title="Color"
              md={12}
            >
              <ColorField source="color" {...props} />
            </ItemDetail>
          </div>
        </div>

        <div className={classes.bigBox}>
          <ItemDetail
            title="Description"
          >
            <RichTextField source="description" {...newProps} />
          </ItemDetail>
        </div>
        <div className={classes.bigBox}>
          <ItemDetail
            title="Legal Text"
          >
            <RichTextField source="legalText" {...newProps} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <div className={classes.normalBoxLeft}>
            <ItemDetail
              title="Start Date"
              center
              md={3}
            >
              <DateField source="startDate" locales="fr-FR" {...props} />
            </ItemDetail>
          </div>
          <div className={classes.normalBoxRight}>
            <ItemDetail
              title="End Date"
              center
              md={3}
            >
              <DateField source="endDate" locales="fr-FR" {...props} />
            </ItemDetail>
          </div>
        </div>
        <div className={classes.normalBox}>
          <div className={classes.normalBoxLeft}>
            <ItemDetail
              title={translate('promotion.edit.orderValidity')}
              md={12}
            >
              <TextField source="daysForOrdersValidity" {...props} />
            </ItemDetail>
          </div>
          <div className={classes.normalBoxRight}>
            <ItemDetail
              title={translate('promotion.edit.orderCancellation')}
              md={12}
            >
              <TextField source="daysUntilOrderCancellation" {...props} />
            </ItemDetail>
          </div>
        </div>
      </div>
      <div className={classes.columnRight}>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Status"
          >
            <span>{promoStatus}</span>
          </ItemDetail>
        </div>
        <div className={classes.imageBox}>
          <ItemDetail
            title="Image"
          >
            <ImageField source="imageUrl" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <div className={classes.stockTitle}> Stock Management </div>
        </div>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Initial Stock"
            md={12}
          >
            <TextField source="promotionStock.initialStock" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Safety Stock"
            md={12}
          >
            <TextField source="promotionStock.safetyStock" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Available Stock"
            md={12}
          >
            <TextField source="promotionStock.availableStock" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Real Stock"
            md={12}
          >
            <TextField source="promotionStock.realStock" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.normalBox}>
          <ItemDetail
            title="Pending Stock"
            md={12}
          >
            <TextField source="promotionStock.pendingStock" {...props} />
          </ItemDetail>
        </div>
        <div className={classes.bigBox}>
          <ItemDetail
            title="Retailers"
            md={12}
          >
            {promoRetailers}
          </ItemDetail>
        </div>
      </div>
    </Box>
  );
};
export default PromotionShowLayout;

/*
 <TextField
                type="number"
                source="points"
                InputLabelProps={{
                  shrink: true,
                }}
              />
 */
