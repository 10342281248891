import * as React from 'react';
import {
  integerToOneDecimalFloat,
  GET_SYNDICATION_URL,
} from './utils';

const VALID_EAN_LENGTH_13 = 13;
const VALID_EAN_LENGTH_6 = 6;
const MAX_NAME_LENGTH = 800;
const MAX_PACKAGING_LENGTH = 100;
const MAX_DESC_LENGTH = 6000;
const MAX_EAN_FILTER_LENGTH = 5000;
const MAX_EAN_SYNDICATION_LENGTH = 6000;
const FILTER_SEPARATOR = ',';
const EXCEPTION_EAN_EXISTS = 'ProductAlreadyExistsException';

const SYNDICATION_CLIENT_DEFAULT = 'carrefour_widget';
const SYNDICATION_ACTION_ADD = 'syndicate';
const SYNDICATION_ACTION_DEL = 'unsyndicate';

const ENERGY = 'energy';
const FAT = 'fat';
const SUGAR = 'sugar';
const FIBER = 'fiber';
const PROTEIN = 'protein';
const SALT = 'salt';

const DEFAULT_ALLERGENS = {
  milk: 'NA',
  soybeans: 'NA',
  peanuts: 'NA',
  celery: 'NA',
  eggs: 'NA',
  molluscs: 'NA',
  'tree nuts': 'NA',
  fish: 'NA',
  mustard: 'NA',
  'sesame seeds': 'NA',
  'sulfur dioxide and sulfits': 'NA',
  lupine: 'NA',
  crustaceans: 'NA',
  gluten: 'NA',
};

const VALID_ALLERGENS = [
  'milk',
  'soybeans',
  'peanuts',
  'celery',
  'eggs',
  'molluscs',
  'tree nuts',
  'fish',
  'mustard',
  'sesame seeds',
  'sulfur dioxide and sulfits',
  'lupine',
  'crustaceans',
  'gluten',
];

const isValidEan = string => (string?.length >= VALID_EAN_LENGTH_6
  && string?.length <= VALID_EAN_LENGTH_13);
const isValidName = string => string?.length <= MAX_NAME_LENGTH;
const isValidPackaging = string => string === undefined
  || string === null
  || string?.length <= MAX_PACKAGING_LENGTH;
const isValidDesc = string => string === undefined
  || string === null
  || string?.length <= MAX_DESC_LENGTH;

const nutriscoreOptions = [
  { id: 'A', name: 'A', color: '#00803D' },
  { id: 'B', name: 'B', color: '#86BC25' },
  { id: 'C', name: 'C', color: '#FFCC00' },
  { id: 'D', name: 'D', color: '#EF7D00' },
  { id: 'E', name: 'E', color: '#E63312' },
  { id: 'NA', name: '-', color: '#FFF' },
];
const nutritionalOptions = [
  { id: 'ok', name: 'Food' },
  { id: 'bebida', name: 'Drink' },
  { id: 'queso', name: 'Cheese' },
  { id: 'grasa', name: 'Fat' },
  { id: 'no', name: 'No' },
  { id: null, name: '-' },
];
const NutriScoreOptionField = ({ record }) => (
  <div style={{ width: '100%' }}>
    <span>{record.name}</span>
    <span style={{
      marginLeft: '5px', backgroundColor: record.color, width: '85%', display: 'inline-block',
    }}
    >&nbsp;
    </span>
  </div>
);
const readAllergens = (allergens) => {
  const object = DEFAULT_ALLERGENS;

  if (allergens) {
    allergens.forEach((item) => {
      if (VALID_ALLERGENS.includes(item?.allergen?.code)) {
        object[item?.allergen?.code] = item?.value;
      }
    });
  }

  return object;
};
const parseLabels = (labels) => {
  if (labels && labels.length) {
    const output = [];
    labels.map(label => output.push(label.name));

    return output;
  }

  return labels;
};

const buildProduct = product => ({
  ...product,
  uuid: product?.id,
  id: product?.ean,
  active: product?.active || null,
  image: product?.images[0] ? product?.images[0]?.url : '',
  brand: product?.brand?.name ? product?.brand?.name : null,
  category: product?.category?.id ? product?.category?.id : null,
  nutriscore: product?.composition?.nutritionInformation?.nutriscore || null,
  allergens: readAllergens(product?.composition?.nutritionInformation?.allergens),
  nutritionalInfo: product?.composition?.group?.id || null,
  compositionText: product?.composition?.text || null,
  contents: {
    calories: product?.composition?.nutritionInformation?.contents
      .find(item => item.code === ENERGY)?.quantity || null,
    grasSat: integerToOneDecimalFloat(product?.composition?.nutritionInformation?.contents
      .find(item => item.code === FAT)?.quantity) || null,
    sucres: integerToOneDecimalFloat(product?.composition?.nutritionInformation?.contents
      .find(item => item.code === SUGAR)?.quantity) || null,
    fibres: integerToOneDecimalFloat(product?.composition?.nutritionInformation?.contents
      .find(item => item.code === FIBER)?.quantity) || null,
    protein: integerToOneDecimalFloat(product?.composition?.nutritionInformation?.contents
      .find(item => item.code === PROTEIN)?.quantity) || null,
    sodium: integerToOneDecimalFloat(product?.composition?.nutritionInformation?.contents
      .find(item => item.code === SALT)?.quantity) || null,
  },
  labels: parseLabels(product?.labels),
});

const buildContentToSubmit = (formValue) => {
  const output = [];
  if (formValue?.calories) {
    output.push({ name: ENERGY, quantity: parseFloat(formValue.calories) });
  }
  if (formValue?.grasSat) {
    output.push({ name: FAT, quantity: parseFloat(formValue.grasSat) });
  }
  if (formValue?.sucres) {
    output.push({ name: SUGAR, quantity: parseFloat(formValue.sucres) });
  }
  if (formValue?.fibres) {
    output.push({ name: FIBER, quantity: parseFloat(formValue.fibres) });
  }
  if (formValue?.protein) {
    output.push({ name: PROTEIN, quantity: parseFloat(formValue.protein) });
  }
  if (formValue?.sodium) {
    output.push({ name: SALT, quantity: parseFloat(formValue.sodium) });
  }
  return output;
};
const parseAllergens = (allergens) => {
  const output = [];
  if (allergens) {
    Object.keys(allergens).forEach((key) => {
      output.push({
        label: key,
        value: allergens[key],
      });
    });
  }
  return output === [] ? null : output;
};
const isEmpty = currentValue => currentValue === null || currentValue === undefined || currentValue === '';
const sendNullIfEmpty = field => (isEmpty(field) ? null : field);
const submitProduct = (values) => {
  const {
    name,
    packaging,
    description,
    nutriscore,
    category,
    brand,
    compositionText,
    nutritionalInfo,
    contents,
    radioValues,
    labels,
    image,
    imageUrl,
    active,
  } = values;
  const parsedAllergens = parseAllergens(radioValues);
  const composition = {
    text: compositionText,
    group: nutritionalInfo,
    nutritionInformation: {
      nutriscore: (nutritionalInfo === 'no' || nutriscore === 'NA') ? null : nutriscore,
      contents: nutritionalInfo === 'no' ? [] : buildContentToSubmit(contents),
      allergens: parsedAllergens,
    },
  };
  const input = {
    packaging: sendNullIfEmpty(packaging),
    description: sendNullIfEmpty(description),
    name: sendNullIfEmpty(name),
    imageUrl: imageUrl || sendNullIfEmpty(image),
    composition,
    brand,
    categoryId: sendNullIfEmpty(category),
    labels,
    active,
  };
  return { ...input };
};
const buildComposition = (props) => {
  const {
    compositionText,
    nutriscore,
    calories,
    grasSat,
    sucres,
    fibres,
    protein,
    sodium,
    nutritionalInfo,
    radioValues,
  } = props;
  const contents = {
    calories,
    grasSat,
    sucres,
    fibres,
    protein,
    sodium,
  };
  const parsedAllergens = parseAllergens(radioValues);
  const composition = {
    text: compositionText,
    group: nutritionalInfo === '' ? null : nutritionalInfo,
    nutritionInformation: {
      nutriscore: (nutritionalInfo === 'no' || nutriscore === 'NA') ? null : nutriscore,
      contents: nutritionalInfo === 'no' ? [] : buildContentToSubmit(contents),
      allergens: parsedAllergens,
    },
  };

  return { ...composition };
};

const validateFloatWithOneDecimal = (value) => {
  const hexRegex = /^\d+\.\d$/;
  if (value && !hexRegex.test(value)) {
    return 'Please insert a number with 1 decimal.';
  }
  return null;
};

const validateIntegerNumber = (value) => {
  const hexRegex = /^\d+?$/;
  if (value && !hexRegex.test(value)) {
    return 'Please insert a number without decimals.';
  }
  return null;
};
const buildCreateInput = (values) => {
  const { data } = values;
  const {
    ean,
    name,
    brand,
    packaging,
    category,
    description,
    image,
    compositionText,
    nutriscore,
    nutritionalInfo,
    radioValues,
    labels,
  } = data;

  const calories = data.contents?.calories;
  const grasSat = data.contents?.grasSat;
  const sucres = data.contents?.sucres;
  const fibres = data.contents?.fibres;
  const protein = data.contents?.protein;
  const sodium = data.contents?.sodium;
  const compositionValues = [
    compositionText,
    nutriscore,
    calories,
    grasSat,
    sucres,
    fibres,
    protein,
    sodium,
    nutritionalInfo,
    radioValues,
  ];
  const isEmptyComposition = compositionValues.every(isEmpty);
  const preparedData = {
    ean: sendNullIfEmpty(ean),
    brand: sendNullIfEmpty(brand),
    name: sendNullIfEmpty(name),
    packaging: sendNullIfEmpty(packaging),
    categoryId: sendNullIfEmpty(category),
    labels: sendNullIfEmpty(labels),
    description: sendNullIfEmpty(description),
    imageUrl: sendNullIfEmpty(image),
    composition: isEmptyComposition ? null : buildComposition({
      compositionText,
      nutriscore,
      calories,
      grasSat,
      sucres,
      fibres,
      protein,
      sodium,
      nutritionalInfo,
      radioValues,
    }),
  };

  return { input: { ...preparedData } };
};

const areValidAllergens = (allergens) => {
  const validValues = ['YES', 'NO', 'TRACES', 'NA'];
  let result = true;

  if (allergens) {
    // eslint-disable-next-line consistent-return
    VALID_ALLERGENS.forEach((allergen) => {
      if (!Object.prototype.hasOwnProperty.call(allergens, allergen)
        || (Object.prototype.hasOwnProperty.call(allergens, allergen)
          && !validValues.includes(allergens[allergen]))) {
        result = false;
      }
    });
  }

  return result;
};

const validateProductCreation = (values) => {
  const errors = {};

  if (!values.ean) {
    errors.ean = 'ra.validation.required';
  } else if (!isValidEan(values.ean)) {
    errors.ean = 'Must have between 6 and 13 digits';
  }

  if (!values.name) {
    errors.name = 'ra.validation.required';
  } else if (!isValidName(values.name)) {
    errors.name = `Must have ${MAX_NAME_LENGTH} or fewer characters`;
  }

  if (!isValidPackaging(values.packaging)) {
    errors.packaging = `Must have ${MAX_PACKAGING_LENGTH} or fewer characters`;
  }
  if (!isValidDesc(values.description)) {
    errors.description = `Must have ${MAX_DESC_LENGTH} or fewer characters`;
  }
  if (!areValidAllergens(values.radioValues)) {
    errors.radioValues = 'Must select a value for every allergen';
  }
  return errors;
};

const getSyndicationClientList = (client = SYNDICATION_CLIENT_DEFAULT) => {
  const urlToFetch = GET_SYNDICATION_URL();
  const token = localStorage.getItem('token');

  return fetch(`${urlToFetch}${client}`, {
    method: 'get',
    headers: {
      accept: '*/*',
      authorization: `Bearer ${token}`,
    },
  }).then(response => response.json())
    .then(res => res).catch((error) => {
      throw error;
    });
};

const isEanSyndicatedWithClient = async (ean, client) => {
  const data = await getSyndicationClientList(client);
  const { eans } = data;
  const indexOfExists = -1;

  return eans?.indexOf(ean) !== indexOfExists;
};

const processEanListWithAction = async (client, action, eanSent) => {
  const data = await getSyndicationClientList(client);
  let { eans } = data;
  const originalLength = eans?.length;
  const indexOfExists = -1;

  eanSent.forEach((ean) => {
    const exists = eans?.indexOf(ean) !== indexOfExists;

    if (action === SYNDICATION_ACTION_ADD) {
      if (!exists) {
        eans.push(ean);
      }
    }

    if (action === SYNDICATION_ACTION_DEL) {
      if (exists) {
        eans = eans.filter(eanInList => eanInList !== ean);
      }
    }
  });

  if (eans?.length !== originalLength) {
    return eans;
  }

  return false;
};

const updateSyndicationClientList = async (
  client = SYNDICATION_CLIENT_DEFAULT, action, eanSent,
) => {
  const urlToFetch = GET_SYNDICATION_URL();
  const token = localStorage.getItem('token');
  const listOfEansToSend = await processEanListWithAction(client, action, eanSent);

  if (listOfEansToSend === false) {
    return false;
  }

  return fetch(`${urlToFetch}${client}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      brands: [],
      allBrands: true,
      eans: listOfEansToSend,
      allEans: false,
    }),
  }).then(response => response.json())
    .then(res => res)
    .catch(error => error);
};

export {
  nutritionalOptions,
  nutriscoreOptions,
  isValidName,
  isValidPackaging,
  isValidEan,
  isValidDesc,
  EXCEPTION_EAN_EXISTS,
  NutriScoreOptionField,
  buildProduct,
  submitProduct,
  buildComposition,
  sendNullIfEmpty,
  isEmpty,
  validateFloatWithOneDecimal,
  validateIntegerNumber,
  MAX_NAME_LENGTH,
  MAX_PACKAGING_LENGTH,
  MAX_DESC_LENGTH,
  VALID_EAN_LENGTH_6,
  VALID_EAN_LENGTH_13,
  MAX_EAN_FILTER_LENGTH,
  MAX_EAN_SYNDICATION_LENGTH,
  FILTER_SEPARATOR,
  buildCreateInput,
  validateProductCreation,
  SYNDICATION_CLIENT_DEFAULT,
  SYNDICATION_ACTION_ADD,
  SYNDICATION_ACTION_DEL,
  getSyndicationClientList,
  isEanSyndicatedWithClient,
  updateSyndicationClientList,
  DEFAULT_ALLERGENS,
};
