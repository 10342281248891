import { PIRATE_SURVEY_URL } from '../../lib/utils';

export const createSurvey = (promotion) => {
  const { uuid } = promotion;

  const token = localStorage.getItem('token');

  return () => new Promise((resolve) => {
    fetch(`${PIRATE_SURVEY_URL()}/promotion/${uuid}/survey`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
      .then(response => response.json())
      .then(data => resolve(data));
  });
};

export default createSurvey;
