import moment from 'moment-timezone';
import 'moment/locale/es';

moment.tz.setDefault('America/Panama');
moment.locale('es');
moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
});

const parseToLongDate = (inputDate, separator = '/') => {
  const formatWithSeparator = `DD${separator}MM${separator}YYYY`;
  return moment(inputDate)
    .tz('America/Panama')
    .format(formatWithSeparator);
};

const base10 = 10;
const convert = 1000;
const isExpired = limit => Date.now() >= parseInt(limit, base10);
const calculateExpirationTime = seconds => Date.now() + (parseInt(seconds, base10) * convert);

export { isExpired, calculateExpirationTime, parseToLongDate };
