import {
  downloadCSV,
} from 'react-admin';
import moment from 'moment';
import 'moment/locale/es';
import jsonExport from 'jsonexport/dist';

const exporter = (promotions) => {
  const zero = '0';
  moment.locale('es',
    {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
    });

  const promotionExport = promotions.map((promotion) => {
    const {
      key, name, eans, startDate, endDate, status, promotionType, points, promotionStock, survey,
    } = promotion;
    const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    return {
      key,
      ean: eans?.[0]?.value,
      name,
      type: promotionType?.name,
      sazcoins: points,
      initialStock: promotionStock?.initialStock?.toString() || zero,
      safetyStock: promotionStock?.safetyStock?.toString() || zero,
      realStock: promotionStock?.realStock?.toString() || zero,
      availableStock: promotionStock?.availableStock?.toString() || zero,
      startDate: moment(startDate).isValid() ? moment(startDate).format('L') : '',
      endDate: moment(endDate).isValid() ? moment(endDate).format('L') : '',
      status: `${status.code}. ${status.status}`,
      link: `${baseUrl}/#/promotions/${key}`,
      surveyBackofficeLink: survey?.promotionSurveyBackofficeUrl,
    };
  });

  jsonExport(promotionExport, {
    headers: [
      'key',
      'ean',
      'name',
      'startDate',
      'endDate',
      'status',
      'type',
      'sazcoins',
      'initialStock',
      'availableStock',
      'realStock',
      'safetyStock',
      'link',
      'surveyBackofficeLink',
    ],
    rename: [
      'id_promo',
      'EAN',
      'Nombre',
      'Fecha de inicio',
      'Fecha de fin',
      'Status',
      'Type',
      'sazcoins',
      'Initial Stock',
      'Available Stock',
      'Real Stock',
      'Safety Stock %',
      'Link',
      'Link encuesta',
    ],
  }, (err, csv) => {
    const plainCsv = csv?.replace(/,/g, ';');
    const BOM = '\uFEFF';
    const date = moment().format('YYYYMMDDHHmmss');
    downloadCSV(`${BOM} ${plainCsv}`, `promotionsExport_${date}`);
  });
};


export default exporter;
