import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg';
import useStyles from './styles';

const MyAppBar = (props) => {
  const classes = useStyles(props);
  return (
    <AppBar {...props} className={classes.appBar}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <LogoSVG className={classes.logo} />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
