import React from 'react';

import {
  Datagrid,
  useTranslate,
  ReferenceManyField,
  TextField,
  DateField,
  Pagination,
  Filter,
  useListController,
  TopToolbar,
  ExportButton,
  ListContextProvider,
  FunctionField,
  CheckboxGroupInput, Loading,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import MaterialTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { EmptyList } from '../../lib/components';
import OrderReferenceExpand from './OrderReferenceExpand';
import exporter from '../orders/OrderExporter';
import StatusSelect from '../orders/StatusSelect';
import { ORDER_STATUS_FILTER } from './constants';

const ZERO = 0;
const ONE = 1;
const TWO = 2;

const useStyles = makeStyles(
  theme => ({
    content: {
      paddingTop: theme.spacing(ONE),
      paddingLeft: theme.spacing(TWO),
      paddingRight: theme.spacing(TWO),
    },
    link: {
      textDecoration: 'none',
      color: 'blue',
      width: '100%',
      display: 'inline-block',
    },
    toolbar: {
      alignItems: 'flex-start',
      paddingRight: ZERO,
      justifyContent: 'space-between',
    },
  }),
  { name: 'OrderReferenceList' },
);

const LinkTextField = ({
  basePath, record = {}, source, addLabel, ...rest
}) => {
  const classes = useStyles();
  const to = `/users/${record?.user?.id}`;
  return (
    <TextField
      className={classes.link}
      source={source}
      record={record}
      component={Link}
      to={to}
      {...rest}
    />
  );
};


const OrderList = (props) => {
  const { currentFilter } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const listContext = useListController(props);
  const { loading } = listContext;

  const filterValues = {
    ...listContext.filterValues,
    ...currentFilter,
  };

  const getLatestDate = (statusHistory, source) => {
    let filterSource;
    if (source === 'requestedAt') {
      filterSource = 'REQUESTED';
    } else if (source === 'expiredAt') {
      filterSource = 'EXPIRED';
    } else if (source === 'redeemedAt') {
      filterSource = 'REDEEMED';
    } else if (source === 'cancelledAt') {
      filterSource = 'CANCELLED';
    } else if (source === 'evaluatedAt') {
      filterSource = 'EVALUATED';
    }

    const latest = statusHistory
      ?.filter(item => item.status === filterSource)
      ?.reduce((a, b) => (new Date(a.statusDate) > new Date(b.statusDate) ? a : b), []);
    return latest?.statusDate;
  };

  const getModifiedData = (newData) => {
    const modifiedData = {};
    Object.keys(newData).forEach((key) => {
      modifiedData[key] = { ...newData[key] };
      modifiedData[key].requestedAt = getLatestDate(modifiedData[key].statusHistory, 'requestedAt');
      modifiedData[key].expiredAt = getLatestDate(modifiedData[key].statusHistory, 'expiredAt');
      modifiedData[key].redeemedAt = getLatestDate(modifiedData[key].statusHistory, 'redeemedAt');
      modifiedData[key].cancelledAt = getLatestDate(modifiedData[key].statusHistory, 'cancelledAt');
      modifiedData[key].evaluatedAt = getLatestDate(modifiedData[key].statusHistory, 'evaluatedAt');
    });
    return modifiedData;
  };

  const newListContext = { ...listContext, data: getModifiedData(listContext.data) };

  if (loading) {
    return <Loading />;
  }

  return (
    <ListContextProvider value={{ ...newListContext, filterValues }}>
      <TopToolbar className={classes.toolbar}>
        <Filter>
          <MaterialTextField
            alwaysOn
            label={translate('user.list.username')}
            onChange={(e) => {
              if (e.target?.value?.length > TWO) {
                props.setUsername(e.target.value);
              } else if (!e.target?.value) {
                props.setUsername(undefined);
              }
            }}
          />
          <CheckboxGroupInput
            source="status"
            choices={ORDER_STATUS_FILTER}
            alwaysOn
            label={translate('orders.list.status')}
          />
        </Filter>
        <Toolbar>
          <ExportButton
            resource={listContext.resource}
            sort={listContext.currentSort}
            filterValues={listContext.filterValues}
            maxResults={10000}
            exporter={exporter}
            data-testid="export-orders-button"
          />
        </Toolbar>
      </TopToolbar>
      <Datagrid optimized expand={<OrderReferenceExpand />}>
        <FunctionField
          source="user.username"
          label={translate('user.list.username')}
          render={record => (
            record?.user ? (
              <LinkTextField
                source="user.username"
                record={record}
                basePath="user"
                sortBy="username"
              />
            ) : (
              <Typography component="span" variant="body2" style={{ fontStyle: 'italic' }}>
                {translate('orders.list.userDeleted')}
              </Typography>
            )
          )}
        />
        <DateField source="requestedAt" locales="fr-FR" label={translate('orders.list.requestedAt')} />
        <DateField source="expiredAt" locales="fr-FR" label={translate('orders.list.expiredAt')} />
        <DateField source="evaluatedAt" locales="fr-FR" label={translate('orders.list.evaluatedAt')} />
        <TextField source="retailer.name" locales="fr-FR" label={translate('orders.list.retailer')} />
        <FunctionField
          source="status"
          render={record => (
            <StatusSelect
              id={record?.id}
              status={record?.status}
              promotionStatus={record?.promotion?.status?.key}
              promotionName={record?.promotion?.name}
              promotionType={record?.promotion?.promotionType?.name}
              retailerList={record?.promotion?.retailers}
              orderRetailer={record?.retailer?.id}
            />
          )}
        />
      </Datagrid>
      <Pagination {...listContext} />
    </ListContextProvider>
  );
};


const OrderReferenceList = (props) => {
  const {
    promotionId, record, hasShow, hasList, hasEdit, addLabel, ...rest
  } = props;
  const classes = useStyles(props);
  const [username, setUsername] = React.useState(undefined);

  return (
    <div className={classes.content}>
      <ReferenceManyField
        key={promotionId}
        reference="orders"
        target="promotionId"
        filter={{
          promotionKey: promotionId,
          username,
        }}
        sort={{
          // Sort needs to be fixed from BE before it can be implemented in FE
          // field: 'requestedAt', order: 'DESC',
        }}
        empty={<EmptyList />}
        {...rest}
      >
        <OrderList
          {...props}
          currentFilter={{
            promotionKey: promotionId,
            username,
          }}
          setUsername={setUsername}
        />
      </ReferenceManyField>
    </div>
  );
};

export default OrderReferenceList;
