import RetailerIcon from '@material-ui/icons/ShopTwo';
import RetailerEdit from './RetailerEdit';
import RetailerList from './RetailerList';
import RetailerShow from './RetailerShow';

export default {
  list: RetailerList,
  icon: RetailerIcon,
  edit: RetailerEdit,
  show: RetailerShow,
};
