import React from 'react';
import {
  Show,
  TopToolbar,
  EditButton,
  ListButton,
  useTranslate,
  Loading,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PromotionTitle from './PromotionTitle';
import PromotionShowLayout from './PromotionShowLayout';
import { TabbedLayout, Tab } from '../../lib/components';
import OrderReferenceList from './OrderReferenceList';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const PromotionShowActions = ({
  basePath, data, resource,
}) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);
const PromotionShow = ({ permissions, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { id } = props;
  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab label={translate('user.tabs.promoDetail')}>
          <Show
            title={<PromotionTitle />}
            actions={<PromotionShowActions permissions={permissions} />}
            {...props}
          >
            <PromotionShowLayout />
          </Show>
        </Tab>
        <Tab label={translate('user.tabs.orders')} path="orders">
          {
          !id && <Loading />
          }
          {id
          && <OrderReferenceList promotionId={id} {...props} />
          }
        </Tab>
      </TabbedLayout>
    </div>
  );
};
export default PromotionShow;
