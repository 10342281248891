import React from 'react';
import get from 'lodash.get';
import pure from 'recompose/pure';

const ColorFieldComponent = ({ source, record = {}, className }) => (
  <div style={{ display: 'flex' }}>
    <div style={{
      width: '20px',
      height: '20px',
      background: get(record, source),
      marginRight: '5px',
    }}
    />
    <span className={className}>{get(record, source)}</span>
  </div>
);

const ColorField = pure(ColorFieldComponent);

export default ColorField;
