import React, { Fragment, useState, useEffect } from 'react';
import {
  ImageInput,
  ImageField,
  Button,
  useNotify,
  useDataProvider,
  useInput,
  useTranslate,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { InputHelperText } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import {
  FormHelperText, FormControl,
} from '@material-ui/core';
import avatar from '../../../platform/assets/images/user/profile/avatar_42_42.png';
import ShowImage from './ShowImage';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 132,
    marginTop: '15px',
  },
}));

const EditDefaultValues = {
  defaultImage: avatar,
};

const ShowProfileImageField = ({
  customSource, url, id, previewWidthAndHeight, onChange,
}) => {
  if (!url) {
    return <ImageField source="defaultImage" record={EditDefaultValues} />;
  }
  const getCacheSuffix = () => (`?cache=${new Date().getTime()}`);
  return (
    <ShowImage
      src={url + getCacheSuffix()}
      id={id}
      customSource={customSource}
      previewWidth={previewWidthAndHeight ? previewWidthAndHeight.width : '42'}
      previewHeight={previewWidthAndHeight ? previewWidthAndHeight.height : '42'}
      onChange={onChange}
    />
  );
};

const ImageQuickCreateButton = (props) => {
  const [showDialog, setDialogue] = useState(false);
  const [image, setImage] = useState();
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const form = useForm();
  const { originalUrl, informationComponent, defaultValue } = props;
  const [orginalUrl, setOriginalUrl] = useState(originalUrl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!originalUrl && defaultValue) {
      setOriginalUrl(defaultValue);
    } else {
      setOriginalUrl(originalUrl);
    }
  }, [originalUrl]);

  const {
    id, accept, maxSize, label, customSource,
    placeholder, maxImageWidth, maxImageHeight, previewWidthAndHeight,
    onChange,
  } = props;
  const {
    meta: { touched, error }, helperText,
  } = useInput(props);
  const checkSizes = (maxImageHeight || false) && (maxImageWidth || false);

  const handleChange = (file) => {
    setImage(file);
  };
  const clearSource = () => {
    form.change('imagePreview', undefined);
    setDialogue(false);
  };
  const approve = (file) => {
    setLoading(true);
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if ((!checkSizes) || (img.width <= maxImageWidth && img.height <= maxImageHeight)) {
        const { size } = file;
        const chunk = size;
        return dataProvider
          .create(customSource, { id, input: { size, chunk }, file })
          .then(({ data }) => {
            if (data) {
              setOriginalUrl(data.data);
              clearSource();
              form.change('imageUrl', data.data);
              if (onChange) {
                onChange('imageUrl', data.data);
              }
              setDialogue(false);
              notify(translate('image.success'), 'success');
            } else {
              notify(translate('image.tryagin'), 'warning');
            }
            setLoading(false);
          })
          .catch((err) => {
            notify(`${translate('image.error')}:, ${err.message}`, 'warning');
            setLoading(false);
          });
      }
      notify(translate('image.resolutionError'), 'warning');
      return null;
    };
  };

  return (
    <Fragment>
      <ShowProfileImageField
        id={id}
        url={orginalUrl}
        customSource={customSource}
        previewWidthAndHeight={previewWidthAndHeight}
        onChange={onChange}
      />
      <br />
      <FormControl
        error={!!(touched && error)}
      >
        <Button className={classes.button} variant="outlined" onClick={() => setDialogue(true)} label="image.butonText">
          <IconContentAdd />
        </Button>
        <FormHelperText
          error={!!error}
        >
          <InputHelperText
            error={error}
            helperText={helperText}
            touched={touched}
          />
        </FormHelperText>
      </FormControl>
      <Dialog fullWidth open={showDialog} onClose={() => clearSource()} aria-label="Create image">
        <DialogTitle> {translate('image.butonText')}</DialogTitle>
        <DialogContent>
          {informationComponent && informationComponent()}
          <ImageInput
            source="imagePreview"
            maxSize={maxSize}
            multiple={false}
            label={label}
            accept={accept}
            onChange={img => handleChange(img)}
            placeholder={placeholder}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </DialogContent>
        <DialogActions>

          <Button
            label="ra.action.save"
            disabled={!image || loading}
            onClick={() => approve(image)}
          >
            <IconSave />
          </Button>
          <Button onClick={() => clearSource()} label="ra.action.cancel">
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ImageQuickCreateButton;
