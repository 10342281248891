import React, { useEffect, useState } from 'react';
import { useNotify, useRefresh, useTranslate } from 'ra-core';
import { SelectInput, SimpleForm, useDataProvider } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';

import { commentStatusStyles } from '../../resources/comments/styles';
import { STATUS_TYPES } from '../utils/Comments';

const ReviewsStatusSelect = ({ record }) => {
  const classes = commentStatusStyles();
  const { id } = record;
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const [input, setInput] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (input && id) {
      setLoading(true);
      dataProvider.update('comments', {
        id: `${id}`,
        data: { ...input },
      })
        .then(() => {
          notify(translate('resources.comments.status.success'), 'success');
          setLoading(false);
          setInput(null);
          refresh();
        })
        .catch((error) => {
          notify(error?.message, 'error');
          setLoading(false);
          setInput(null);
        });
    }
  }, [input]);


  const handleChange = (event) => {
    const sendInput = {
      certified: record?.certified,
      status: event.target.value,
      text: record?.text,
    };

    setInput(sendInput);
  };
  if (loading) {
    return <CircularProgress className={classes.icon} color="primary" />;
  }
  return (
    <SimpleForm toolbar={null} record={record} className={classes.form}>
      <SelectInput
        onChange={handleChange}
        source="status"
        choices={[
          { id: STATUS_TYPES.REJECTED, name: STATUS_TYPES.REJECTED },
          { id: STATUS_TYPES.PUBLISHED, name: STATUS_TYPES.PUBLISHED },
        ]}
        label=""
        formClassName={classes.status}
        fullWidth
      />
    </SimpleForm>
  );
};

export default ReviewsStatusSelect;
