import React from 'react';
import {
  Filter, TextInput, minLength, maxLength, regex,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  filter: {
    height: '70px',
  },
}));
const CommentFilter = ({ translate, permissions, ...props }) => {
  const maxSearch = 13;
  const minSearch = 6;
  const validatePatternNum = regex(/^\d*$/, 'Please introduce between 6 and 13 digits');
  const validateEan = [
    minLength(minSearch, 'Please introduce between 6 and 13 digits'),
    maxLength(maxSearch, 'Please introduce between 6 and 13 digits'),
    validatePatternNum,
  ];
  const classes = useStyles();
  return (
    <Filter {...props} data-testid="user-search">
      <TextInput className={classes.filter} validate={validateEan} source="ean" alwaysOn label={translate('comment.edit.ean')} />
    </Filter>
  );
};

export default CommentFilter;
