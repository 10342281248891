import memoize from 'lodash/memoize';
import { useMediaQuery } from '@material-ui/core';

import React from 'react';
import {
  useTranslate,
  Filter,
  List,
  SimpleList,
  TextInput,
  EditButton,
  ShowButton,
  TextField,
  CheckboxGroupInput,
} from 'react-admin';
import { EmptyList, LinkTextField, ListActions } from '../../lib/components';
import retailerExporter from './RetailerExporter';
import { RETAILERS } from './constants';
import CustomDatagrid from '../promotions/CustomDatagrid';

const RetailerFilter = ({ translate, permissions, ...props }) => (
  <Filter {...props} data-testid="retailer-search">
    <TextInput source="name" alwaysOn label={translate('retailers.filter.name')} />
    <CheckboxGroupInput
      source="active"
      choices={[
        { id: true, name: 'Active' },
        { id: false, name: 'Inactive' },
      ]}
      alwaysOn
      label={translate('retailers.filter.status')}
    />
  </Filter>
);

const rowClick = memoize(permissions => () => (permissions === 'admin'
  ? Promise.resolve('edit')
  : Promise.resolve('show')));

const RetailerList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const { basePath } = props;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      empty={<EmptyList />}
      hasCreate
      actions={<ListActions module={RETAILERS} exporter={retailerExporter} maxResults={null} />}
      filters={<RetailerFilter translate={translate} permissions={permissions} />}
      sort={{}}
    >
      {useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
        <SimpleList
          rowClick={rowClick(permissions)}
          primaryText={record => record.name}
          data-testid="retailers-list-mobile-data-grid"
        />
      ) : (
        <CustomDatagrid
          data-testid="retailers-list-data-grid"
          data-cy="retailer-list"
          rowClick="edit"
        >
          <LinkTextField
            basePath={basePath}
            permissions={permissions}
            source="name"
            label={translate('retailers.list.name')}
            data-cy="retailer-list-item"
          />
          <TextField source="status" label={translate('retailers.list.status')} />
          <ShowButton label="" width="3%" />
          <EditButton label="" width="3%" />
        </CustomDatagrid>
      )}
    </List>
  );
};

export default RetailerList;
