import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  useNotify,
  useTranslate,
} from 'react-admin';
import { createSurvey } from './buildQuerySurvey';
import { validateButton } from './PromotionUtils';

import { createSurveyButton } from './createSurveyButtonStyles';


const CreateSurveyButton = (props) => {
  const {
    recordValues, recordData, eansLength, errorMessage,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [surveyUrl, setSurveyUrl] = useState(null);
  const notify = useNotify();
  const classes = createSurveyButton();
  const translate = useTranslate();

  useEffect(() => {
    setSurveyUrl(recordData?.survey?.promotionSurveyBackofficeUrl);
  }, [recordData]);

  const handleOnClick = () => {
    if (surveyUrl) {
      window.open(surveyUrl, '_blank');
    } else {
      setIsLoading(true);
      const postCreateSurvey = createSurvey(recordValues);
      postCreateSurvey()
        .then((data) => {
          setIsLoading(false);

          if (data.error) {
            notify(data.error_message, 'warning');
          } else {
            window.open(data.promotion_survey_backoffice_url, '_blank');
            setSurveyUrl(data.promotion_survey_backoffice_url);
          }
        });
    }
  };

  const renderCircularProgress = () => {
    if (isLoading) {
      return (
        <CircularProgress
          size={20}
          thickness={1}
          className={classes.loadingCircle}
        />
      );
    }

    return null;
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnClick}
          disabled={
            isLoading
            || validateButton(recordData, recordValues, eansLength)
          }
          fullWidth
        >
          {renderCircularProgress()}
          {surveyUrl ? translate('promotion.survey.edit') : translate('promotion.survey.create')}
        </Button>
      </div>
      <span className={classes.errorMessage}>{errorMessage}</span>
    </div>
  );
};


export default CreateSurveyButton;
