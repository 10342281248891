import React from 'react';
import {
  Edit, SimpleForm, useNotify,
} from 'react-admin';
import Form from './Form';

import { validateProductCreation } from '../../lib/utils/products';
import ProductToolbar from './ProductToolbar';

const ProductEdit = (props) => {
  const notify = useNotify();
  const zero = 0;
  const onSuccess = () => {
    notify('products.edit.updated', 'success');
    window.scrollTo({ top: zero, behavior: 'smooth' });
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      actions={null}
      resource="products"
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm validate={validateProductCreation} toolbar={<ProductToolbar isEdit />} redirect="edit">
        <Form isEdit />
      </SimpleForm>
    </Edit>
  );
};

export default ProductEdit;
